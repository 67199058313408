export const errorMessage = (error) => {
  if (!error) {
    return 'Empty error';
  }

  if (error instanceof Error) {
    return error.message;
  }

  // error from requestHelper
  if (typeof error === 'object' && error.statusCode !== undefined) {
    return `Request error: ${error.statusCode} ${error.message ? error.message : ''}`;
  }

  return error;
};

export const logError = (error) => {
  if (console && console.error) {
    console.error(error);
  }
};
