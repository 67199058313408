import config from 'react-global-configuration';

const baseDomainParts = window.location.host.split(".").slice(1);
const apiUrl = "https://" + ["api"].concat(baseDomainParts).join(".");
const appUrl = "https://" + ["app"].concat(baseDomainParts).join(".");
const adminUrl = "https://" + ["admin"].concat(baseDomainParts).join(".");

config.set(Object.assign({}, UXPinConfig, {
  API_URL: apiUrl,
  APP_URL: appUrl,
  ADMIN_URL: adminUrl,
  BASE_URL: "/",
  SUPPORT_TOOLS_BACKEND_URL: `${appUrl}/mod/API/SupportTools`
}));
