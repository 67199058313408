import { adminsActions } from '../../constants/admins.constants';

export default function adminsReducer(state = {
  admins: [],
  monitoring: [],
  isFetching: false,
  isFetchingMonitoring: false,
  error: null,
}, action) {
  switch (action.type) {
    case adminsActions.ADMINS_SET_IS_FETCHING:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      });
    case adminsActions.ADMINS_SET_IS_FETCHING_MONITORING:
      return Object.assign({}, state, {
        isFetchingMonitoring: action.isFetchingMonitoring,
      });
    case adminsActions.ADMINS_SET_ADMINS:
      return Object.assign({}, state, {
        admins: action.admins,
        isFetching: false,
        error: null,
      });
    case adminsActions.ADMINS_SET_MONITORING:
      return Object.assign({}, state, {
        monitoring: action.monitoring,
        isFetchingMonitoring: false,
      });
    case adminsActions.ADMINS_SET_ERROR:
      return Object.assign({}, state, {
        error: action.error,
      });
    default:
      return state;
  }
}
