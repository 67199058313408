import { isEmpty } from 'lodash';
import { loginFetch } from './login.actions';
import { toolsActions } from '../../constants/tools.constants';
import requestHelper from '../../helpers/requestHelper';
import { logError, errorMessage } from '../../helpers/errorHandler';
import config from 'react-global-configuration';

const STATUS_CHECK_INTERVAL = 2000;
const CACHE_CLEARED_FLASH_TIME = 3000;
const SUPPORT_TOOLS_URL = config.get('SUPPORT_TOOLS_BACKEND_URL');

const permissionsErrorMessages = ['permissions', 'error_wrong_token'];
let cacheClearedTimeout;

const handleLoginError = (dispatch, error) => {
  if (permissionsErrorMessages.includes(errorMessage(error))) {
    dispatch(loginFetch());
  } else {
    logError(error);
  }
};

export const setCacheClearing = (clearing) => ({
  type: toolsActions.CACHE_CLEARING,
  clearing,
});

export const setCacheCleared = (cleared) => ({
  type: toolsActions.CACHE_CLEARED,
  cleared,
});

const flashCacheCleared = (dispatch) => {
  dispatch(setCacheCleared(true));
  clearTimeout(cacheClearedTimeout);

  cacheClearedTimeout = setTimeout(() => {
    dispatch(setCacheCleared(false));
  }, CACHE_CLEARED_FLASH_TIME);
};

export const setPrototypeExporting = (idProject, isExporting) => ({
  type: toolsActions.PROTOTYPE_EXPORT,
  idProject,
  isExporting,
  result: null,
  error: null,
});

export const setPrototypeImporting = () => ({
  type: toolsActions.PROTOTYPE_IMPORTING,
});

export const setPrototypeExported = (idProject, result) => ({
  type: toolsActions.PROTOTYPE_EXPORT,
  idProject,
  isExporting: false,
  result,
  error: null,
});

export const setPrototypeImported = (prototype) => ({
  type: toolsActions.PROTOTYPE_IMPORTED,
  prototype,
});

export const setExportError = (idProject, error) => ({
  type: toolsActions.PROTOTYPE_EXPORT,
  idProject,
  isExporting: false,
  result: null,
  error,
});

export const setImportError = (error) => ({
  type: toolsActions.PROTOTYPE_IMPORT_ERROR,
  error,
});

export const checkStatus = async (dispatch, token) => {
  const url = `${SUPPORT_TOOLS_URL}/status/?token=${token}`;
  let response;

  try {
    response = await requestHelper.get(url);

    if (!response || response.error) {
      throw new Error(response && response.error);
    }
  } catch (error) {
    handleLoginError(dispatch, error);
    return Promise.reject(errorMessage(error));
  }

  if (!response.error && !response.result) {
    return new Promise((resolve, reject) => setTimeout(() => {
      checkStatus(dispatch, token).then(resolve).catch(reject);
    }, STATUS_CHECK_INTERVAL));
  }

  return response.result;
};

export const triggerExportPrototype = (idProject) => async (dispatch) => {
  dispatch(setPrototypeExporting(idProject, true));

  const url = `${SUPPORT_TOOLS_URL}/export_prototype/`;
  const data = { id_project: idProject };

  try {
    const response = await requestHelper.post(url, data);

    if (!response || response.error || isEmpty(response.token)) {
      throw new Error(response && response.error);
    }

    const result = await checkStatus(dispatch, response.token);
    dispatch(setPrototypeExported(idProject, result));
  } catch (error) {
    handleLoginError(dispatch, error);
    dispatch(setExportError(idProject, errorMessage(error)));
  }
};

export const triggerImportPrototype = (formData) => async (dispatch) => {
  dispatch(setPrototypeImporting());
  const url = `${SUPPORT_TOOLS_URL}/import_prototype/`;

  try {
    const response = await requestHelper.post(url, formData);

    if (!response || response.error || isEmpty(response.token)) {
      throw new Error(response && response.error);
    }

    const result = await checkStatus(dispatch, response.token);
    dispatch(setPrototypeImported(result.prototype));
  } catch (error) {
    handleLoginError(dispatch, error);
    dispatch(setImportError(errorMessage(error)));
  }
};

export const clearCache = (params) => async (dispatch) => {
  const url = `${SUPPORT_TOOLS_URL}/clear_cache/`;
  dispatch(setCacheClearing(true));

  try {
    await requestHelper.post(url, params);
    flashCacheCleared(dispatch);
  } catch (error) {
    handleLoginError(dispatch, error);
  }

  dispatch(setCacheClearing(false));
};
