export const SEARCH = 'search';
export const PAGES = 'pages';
export const PROTOTYPES = 'prototypes';
export const TOOLS = 'tools';
export const DESIGN_SYSTEMS = 'designsystems';
export const ACCOUNTS = 'accounts';
export const ADMINS = 'admins';
export const INVOICES = 'invoices';
export const PLANS = 'plans';
export const EMAILS = 'emails';
export const STORYBOOK_LIBRARIES = 'admin_v3';
