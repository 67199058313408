import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { MenuItem } from './MenuItem.container';

const Menu = ({ modules }) => {
  const renderMenuItems = () => _.map(modules, (item, id) => (
    <MenuItem item={item} key={id} />
  ));

  return (
    <aside className="left-column">
      <header className="left-bar">
        <h1 className="name inline-edit-wrapper">Admin Panel</h1>
      </header>

      <nav className="nav-menu">
        <ul className={classnames(GLOBAL_ELEMENTS.UNORDERED_LIST, 'nav-menu-list')}>
          {renderMenuItems()}
        </ul>
      </nav>
    </aside>
  );
};

Menu.propTypes = {
  modules: PropTypes.object.isRequired,
};

export default Menu;
