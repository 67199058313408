import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccountComponent from './Account.component';
import { accountFetch } from '../../controllers/actions/account.actions';
import { navigateToSearchMode } from '../../controllers/actions/search.actions';
import { searchModes, searchModesUrlsMap } from '../../constants/search.constants';

const mapStateToProps = (state) => {
  const { data, isFetching } = state.account;

  return {
    data,
    isFetching,
    accountSearchUrl: searchModesUrlsMap[searchModes.ACCOUNT],
  };
};

const mapDispatchToProps = (dispatch) => (bindActionCreators({
  accountFetch,
  navigateToSearchMode,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AccountComponent);
