import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { createStore, combineReducers, applyMiddleware } from 'redux';

import { searchReducer } from './reducers/search.reducer';
import { accountReducer } from './reducers/account.reducer';
import userReducer from './reducers/user.reducer';
import { toolsReducer } from './reducers/tools.reducer';
import { designSystemsReducer } from './reducers/designSystems.reducer';
import loginReducer from './reducers/login.reducer';
import pageHistoryReducer from './reducers/pageHistory.reducer';
import adminsReducer from './reducers/admins.reducer';

const middlewares = process.env.NODE_ENV !== 'production' ? [
  thunk, logger,
] : [thunk];

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

const appReducers = combineReducers({
  search: searchReducer,
  account: accountReducer,
  user: userReducer,
  tools: toolsReducer,
  login: loginReducer,
  designSystems: designSystemsReducer,
  pageHistory: pageHistoryReducer,
  admins: adminsReducer,
});

export const appStore = createStoreWithMiddleware(appReducers);

export default appStore;
