import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import formatRangeTimestamp from '../../helpers/formatRangeTimestamp';
import PageContentJson from '../Generic/PageContentJson.component';

const PageHistoryItem = ({ itemMeta, itemData, fetchItem, toggleExpandItem }) => {
  const isExpanded = () => (
    itemData && itemData.isExpanded
  );

  const onItemClick = (e) => {
    e.preventDefault();

    if (!itemData || (isEmpty(itemData.update) && !itemData.isFetching)) {
      fetchItem(itemMeta.idPage, itemMeta.timestamp);
    } else {
      toggleExpandItem(itemMeta.idPage, itemMeta.timestamp);
    }
  };

  const renderTitle = () => (
    <p className="history-log-item-title" onClick={onItemClick}>
      <span className="item-title">{formatRangeTimestamp(itemMeta.timestamp)}</span>
      {itemMeta.email}
    </p>
  );

  const renderMeta = () => {
    if (!isExpanded()) {
      return null;
    }

    return (
      <ul className="history-log-item-meta">
        <li>Token: {itemMeta.token}</li>
        <li>Editor instance id: {itemMeta.editorInstanceId}</li>
      </ul>
    );
  };

  const renderData = () => {
    if (!isExpanded()) {
      return null;
    }

    const content = !isEmpty(itemData.update) && JSON.parse(itemData.update);

    return (
      <p>
        <PageContentJson isLoading={itemData.isFetching} value={content} />
      </p>
    );
  };

  return (
    <li className="history-log-item">
      {renderTitle()}
      {renderMeta()}
      {renderData()}
    </li>
  );
};

PageHistoryItem.propTypes = {
  itemMeta: PropTypes.object.isRequired,
  itemData: PropTypes.object,
  fetchItem: PropTypes.func.isRequired,
  toggleExpandItem: PropTypes.func.isRequired,
};

PageHistoryItem.defaultProps = {
  itemData: {},
};

export default PageHistoryItem;
