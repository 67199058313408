import { connect } from 'react-redux';
import { searchModes } from '../../constants/search.constants';
import PrototypeComponent from './Prototype.component';

export const mapStateToProps = (state) => {
  const { query, prototype, isFetching } = state.search;

  return {
    query: query[searchModes.PROTOTYPE],
    prototype,
    isFetching,
  };
};

export const Prototype = connect(mapStateToProps)(PrototypeComponent);
