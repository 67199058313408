/* eslint-disable react/prop-types,react/destructuring-assignment */
import _ from 'lodash';
import autobind from 'autobind-decorator';
import React from 'react';
import Loader from '../Generic/Loader.component';
import { DataListItem } from '../Generic/DataListItem';
import AccountUsers from './AccountUsers.component';
import { searchModes } from '../../constants/search.constants';
import config from 'react-global-configuration';
import ExternalLink from 'admin/images/external-link.svg';

const APP_URL = config.get('APP_URL');
const ADMIN_URL = config.get('ADMIN_URL');

export default class Account extends React.Component {
  @autobind
  componentWillMount() {
    const { accountFetch } = this.props;
    const { idAccount } = this.props.match.params;

    accountFetch(idAccount);
  }

  @autobind
  renderDuck() {
    const { isFetching } = this.props;

    if (!isFetching) { return null; }

    return (<Loader />);
  }

  @autobind
  getAccountManagerValue() {
    const { account: { manager_id_dms_user: managerIdUser }, managers } = this.props.data;
    return managers[managerIdUser] || 'no manager';
  }

  @autobind
  getAccountOwnerValue() {
    const { owner: { email, firstname, lastname } } = this.props.data;
    const getOwnerNameBracket = (first, last) => {
      if (!first && !last) { return ''; }

      return `(${_.join(_.compact([first, last]), ' ')})`;
    };

    return `${email} ${getOwnerNameBracket(firstname, lastname)}`;
  }

  @autobind
  navigateToAccountSearch(e) {
    e.preventDefault();
    const { navigateToSearchMode } = this.props;
    navigateToSearchMode(searchModes.ACCOUNT);
  }


  @autobind
  renderAccountData() {
    const { data, match: { params: { idAccount } } } = this.props;

    if (_.isEmpty(data)) {
      return null;
    }

    const { account: { name }, mrr } = data;

    return (
      <div>
        <h1 className="title">Account</h1>
        <ul>
          <DataListItem title="Account name:" value={name} />
          <DataListItem title="Account owner:" value={this.getAccountOwnerValue()} />
          <DataListItem title="Manager:" value={this.getAccountManagerValue()} />
          <DataListItem title="MRR:" value={`$${mrr.toFixed(2)}`} />
        </ul>
        <p>
          <a
            href={`${APP_URL}/dms/dmsAccounts/EditRecord/?id_account=${idAccount}`}
            className="blue-link"
            target="_blank"
            el="noopener noreferrer">
            Edit
            <img className="external-icon" src={ExternalLink} alt="External link" />
          </a>
        </p>
        <p>
          <a
            href={`${ADMIN_URL}/accounts/${idAccount}/account_promotions`}
            className="blue-link"
            target="_blank"
            el="noopener noreferrer">
            Promotions
            <img className="external-icon" src={ExternalLink} alt="External link" />
          </a>
        </p>
      </div>
    );
  }

  @autobind
  renderAccountUsers() {
    const { data: { users } } = this.props;

    if (_.isEmpty(users)) {
      return null;
    }

    return (
      <div className="data-section">
        <h2 className="section-title">Users</h2>
        <AccountUsers users={users} />
      </div>
    );
  }

  render() {
    return (
      <div className="main-content">
        <div className="content-container">
          <p className="back-navigation">
            <a href="#" onClick={this.navigateToAccountSearch}>← Results</a>
          </p>

          {this.renderAccountData()}
          {this.renderAccountUsers()}
          {this.renderDuck()}
        </div>
      </div>
    );
  }
}
