export const pageHistoryActions = {
  HISTORY_PAGEID_RESET: 'HISTORY_PAGEID_RESET',
  HISTORY_LIST_FETCH: 'HISTORY_LIST_FETCH',
  HISTORY_LIST_FETCHED: 'HISTORY_LIST_FETCHED',
  HISTORY_LIST_SET: 'HISTORY_LIST_SET',
  HISTORY_ITEM_FETCH: 'HISTORY_ITEM_FETCH',
  HISTORY_ITEM_SET: 'HISTORY_ITEM_SET',
  HISTORY_ITEM_TOGGLE: 'HISTORY_ITEM_TOGGLE',
  HISTORY_LIST_FILTER_SET: 'HISTORY_LIST_FILTER_SET',
};

export const rangeFilter = {
  RANGE_24H: 'last-24h',
  RANGE_ALL: 'all',
  RANGE_RANGE: 'range',
};

export const sortFilter = {
  SORT_ASC: 'asc',
  SORT_DESC: 'desc',
};

export default pageHistoryActions;
