import config from 'react-global-configuration';
import { loginFetch } from './login.actions';
import { userActions } from '../../constants/user.constants';
import requestHelper from '../../helpers/requestHelper';
import { logError, errorMessage } from '../../helpers/errorHandler';
import { navigateToSearchMode } from './search.actions';
import { searchModes } from '../../constants/search.constants';

const SUPPORT_TOOLS_URL = config.get('SUPPORT_TOOLS_BACKEND_URL');

export const userSetError = (error) => ({
  type: userActions.USER_SET_ERROR,
  error,
});

export const userSetResults = (data) => ({
  type: userActions.USER_SET_DATA,
  data,
  error: null,
});

export const userSetIsFetching = (isFetching) => ({
  type: userActions.USER_SET_IS_FETCHING,
  isFetching,
});

export const userSetIsUpdating = (isUpdating) => ({
  type: userActions.USER_SET_IS_UPDATING,
  isUpdating,
});

export const userSetResetPasswordResult = (resetPasswordResult) => ({
  type: userActions.USER_SET_RESET_PASSWORD_RESULT,
  resetPasswordResult,
  isResettingPassword: false,
  error: null,
});

export const userSetIsResettingPassword = (isResettingPassword) => ({
  type: userActions.USER_SET_IS_RESETTING_PASSWORD,
  isResettingPassword,
});

export const userSetIsRemoving = (isRemoving) => ({
  type: userActions.USER_SET_IS_REMOVING,
  isRemoving,
});

export const userFetch = (idUser) => (dispatch) => {
  dispatch(userSetIsFetching(true));
  dispatch(userSetResults({}));

  requestHelper.get(`${SUPPORT_TOOLS_URL}/get_user/?id_user=${idUser}`)
    .then((data) => {
      dispatch(userSetIsFetching(false));
      dispatch(userSetResults(data));
    })
    .catch((error) => {
      if (errorMessage(error) === 'permissions') {
        dispatch(loginFetch());
      } else {
        logError(error);
      }
    });
};

export const userResetPassword = (idUser) => (dispatch) => {
  dispatch(userSetIsResettingPassword(true));

  requestHelper.post(`${SUPPORT_TOOLS_URL}/reset_password/`, { id_dms_user: idUser })
    .then((result) => {
      dispatch(userSetResetPasswordResult(result));
    })
    .catch((error) => {
      dispatch(userSetIsResettingPassword(false));
      dispatch(userSetError(error));
    });
};

export const userUpdate = (idUser, data) => (dispatch) => {
  dispatch(userSetIsUpdating(true));

  requestHelper.post(`${SUPPORT_TOOLS_URL}/update_user/`, {
    id_dms_user: idUser,
    data: JSON.stringify(data),
  })
    .then((result) => {
      dispatch(userSetIsUpdating(false));
      dispatch(userSetResults(result));
    })
    .catch((error) => {
      dispatch(userSetIsUpdating(false));
      dispatch(userSetError(error));
    });
};


export const userRemove = (idUser) => (dispatch) => {
  dispatch(userSetIsRemoving(true));

  requestHelper.post(`${SUPPORT_TOOLS_URL}/remove_user/`, {
    id_dms_user: idUser,
  })
    .then(() => {
      dispatch(userSetIsRemoving(false));
      dispatch(userSetResults({}));
      dispatch(navigateToSearchMode(searchModes.ACCOUNT));
    })
    .catch((error) => {
      dispatch(userSetIsRemoving(false));
      dispatch(userSetError(error));
    });
};
