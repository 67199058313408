export const designSystemsActions = {
  DESIGN_SYSTEM_DUPLICATE_START: 'DESIGN_SYSTEM_DUPLICATE_START',
  DESIGN_SYSTEM_DUPLICATE: 'DESIGN_SYSTEM_DUPLICATE',
  DESIGN_SYSTEM_STATUS: 'DESIGN_SYSTEM_STATUS',
};

export const designSystemStatus = {
  NORMAL: 'normal',
  DUPLICATING: 'duplicating',
};
