/* eslint-disable react/prop-types,react/destructuring-assignment */
import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

const ImportTool = (props) => {
  const inputClassName = 'ds-input__field';

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const idCollection = formData.get('id_collection');
    const file = formData.get('export_file');
    const fileUrl = formData.get('export_file_url');

    if ((!file.size && isEmpty(fileUrl)) || isEmpty(idCollection)) {
      props.setImportError('Please provide prototype export file and project ID');
      return;
    }

    props.importPrototype(formData);
  };

  const renderError = () => {
    if (!props.importError) {
      return null;
    }

    return (
      <p className="import-error">{props.importError}</p>
    );
  };

  const renderResult = () => {
    if (isEmpty(props.imported)) {
      return null;
    }

    const { preview_url: previewUrl } = props.imported;

    return (
      <p className="import-result">
        Imported:
        <a href={previewUrl}>{previewUrl}</a>
      </p>
    );
  };

  const renderSubmit = () => {
    const { isImporting } = props;
    const submitClassName = classnames(inputClassName, 'btn-solid btn-inline');

    const submitProps = {
      type: 'submit',
      value: isImporting ? 'IMPORTING ...' : 'IMPORT',
      className: submitClassName,
    };

    if (isImporting /* || isEmpty(validatedCollection) */) {
      submitProps.disabled = 'disabled';
    }

    return (
      <p><input {...submitProps} /></p>
    );
  };

  return (
    <section className="tools-section">
      <h3 className="subtitle">Prototype import</h3>
      <p>This imports previously exported prototype to provided project.</p>

      <form onSubmit={onSubmit}>
        <p>
          Provide export file URL:
          {' '}
          <input className={inputClassName} type="text" name="export_file_url" />
          <br />
          Or select file:
          {' '}
          <input className={inputClassName} type="file" accept=".uxp" name="export_file" />
        </p>
        <p>
          Provide project ID:
          {' '}
          <input className={inputClassName} type="text" name="id_collection" />
          <span className="input-hint">Prototype will be imported to this project</span>
        </p>
        {renderError()}
        {renderResult()}
        {renderSubmit()}
      </form>
    </section>
  );
};

export default ImportTool;
