import React from 'react';
import { Search } from '../Search/Search.container';

// eslint-disable-next-line react/prop-types
const TopBar = ({ searchActive }) => (
  <header className="top-bar">
    {searchActive ? <Search /> : null}
  </header>
);

export default TopBar;
