import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduce, reverse } from 'lodash';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';

const AdminsMonitoring = ({ isFetchingMonitoring, fetchMonitoring, monitoring }) => {
  const renderLogItem = (item) => {
    const contentArray = reduce(item.diff, (reduced, role, adminEmail) => (
      [...reduced, `${adminEmail}: ${role}`]
    ), []);

    return (
      <li key={item.id} className="monitoring-log-item">
        <span className="item-title">{item.timestamp}</span>
        <span className="item-set">{contentArray.join(', ')}</span>
        {item.user && <span className="item-author">by {item.user}</span>}
      </li>
    );
  };

  return (
    <Fragment>
      <p>
        <input
          type="button"
          value="Load monitoring log"
          className="admins-load-monitoring"
          onClick={fetchMonitoring}
          disabled={isFetchingMonitoring}
        />
      </p>
      <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
        {reverse([...monitoring]).map((item) => renderLogItem(item))}
      </ul>
    </Fragment>
  );
};

AdminsMonitoring.propTypes = {
  isFetchingMonitoring: PropTypes.bool.isRequired,
  monitoring: PropTypes.array.isRequired,
  fetchMonitoring: PropTypes.func.isRequired,
};

export default AdminsMonitoring;
