import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { PageHistory } from './PageHistory.container';
import Loader from '../Generic/Loader.component';
import { PageMetadata } from './PageMetadata.component';

// eslint-disable-next-line react/prop-types
const Page = ({ isFetching, page, query, navigateToPrototype }) => {
  const renderTitle = () => {
    let label;

    if (!isEmpty(page)) {
      label = 'Result';
    } else if (!isFetching && !isEmpty(query)) {
      label = 'Not found';
    }

    return label
      ? <h1 className="title">{label}</h1>
      : null;
  };

  const renderResults = () => {
    if (isFetching && isEmpty(page)) {
      return <Loader />;
    }

    if (isEmpty(page)) {
      return null;
    }

    return (
      <Fragment>
        <PageMetadata data={page} navigateToPrototype={navigateToPrototype} />
        <PageHistory />
      </Fragment>
    );
  };

  return (
    <div className="main-content">
      <div className="content-container">
        {renderTitle()}
        {renderResults()}
      </div>
    </div>
  );
};

Page.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  page: PropTypes.object,
  navigateToPrototype: PropTypes.func.isRequired,
};

Page.defaultProps = {
  page: {},
};

export default Page;
