export const ACCOUNT_STATUS = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  BLOCKED: 'blocked',
  DELETED: 'deleted',
  CANCELED: 'canceled',
  PARKED: 'parked',
};

export default ACCOUNT_STATUS;
