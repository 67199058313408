import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { adminRoles } from '../../constants/admins.constants';

export default class AdminForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      role: adminRoles.CUSTOMER,
    };
  }

  @autobind
  handleChange(event) {
    const field = event.target.name;
    const value = event.target.value;
    this.setState({ [field]: value });
  }

  @autobind
  handleSubmit(event) {
    event.preventDefault();
    const { disabled, onSubmit } = this.props;

    if (!disabled) {
      onSubmit(Object.assign({}, this.state));
    }
  }

  render() {
    const { email, role } = this.state;
    const { disabled } = this.props;

    /* eslint-disable jsx-a11y/no-onchange */
    return (
      <form onSubmit={this.handleSubmit} className="admins-add-form">
        <p>
          <span>
            <label htmlFor="admins-input-email">Email: </label>
            <input
              name="email"
              type="email"
              id="admins-input-email"
              className="admins-inline-input"
              value={email}
              onChange={this.handleChange}
            />
          </span>
          <span className="admins-add-section">
            <label htmlFor="admins-select-role">Role: </label>
            <select name="role" id="admins-select-role" value={role} onChange={this.handleChange}>
              <option value={adminRoles.CUSTOMER}>Non-admin</option>
              <option value={adminRoles.SALES}>Sales</option>
              <option value={adminRoles.ADMINISTRATOR}>Administrator</option>
              <option value={adminRoles.SUPERADMIN}>Superadmin</option>
            </select>
          </span>
          <span className="admins-add-section">
            <input type="submit" value="Submit" className="admins-add-submit" disabled={disabled} />
          </span>
        </p>
      </form>
    );
    /* eslint-enable */
  }
}

AdminForm.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

AdminForm.defaultProps = {
  disabled: false,
};
