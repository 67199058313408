import { designSystemsActions } from '../../constants/designSystems.constants';
import { logError } from '../../helpers/errorHandler';
import requestHelper from '../../helpers/requestHelper';

export const designSystemDuplicateStart = (isStartingDuplication) => ({
  type: designSystemsActions.DESIGN_SYSTEM_DUPLICATE_START,
  isStartingDuplication,
});

export const designSystemDuplicate = (duplicationResponse) => ({
  type: designSystemsActions.DESIGN_SYSTEM_DUPLICATE,
  isStartingDuplication: false,
  duplicationResponse,
});

export const designSystemStatus = (statusResponse) => ({
  type: designSystemsActions.DESIGN_SYSTEM_STATUS,
  statusResponse,
});


export const duplicateDesignSystem = ({ hash, email, newLibraryName }) => (dispatch) => {
  dispatch(designSystemDuplicateStart(true));

  return requestHelper.apiRequest('PUT', '/designSystems/duplicateForOwner', {
    hash,
    email,
    newLibraryName,
  })
    .then((duplicationResponse) => dispatch(designSystemDuplicate(duplicationResponse)))
    .catch((error) => {
      dispatch(designSystemDuplicateStart(false));
      logError(error);
    });
};

export const checkDesignSystemStatus = (idDesignSystem) => (dispatch) => {
  requestHelper.apiRequest('GET', `/designSystems/status/${idDesignSystem}`)
    .then((response) => {
      dispatch(designSystemStatus(response));
    })
    .catch((error) => {
      logError(error);
    });
};
