import _ from 'lodash';
import config from 'react-global-configuration';

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';

function createDmsUrl(url) {
  const paramSeparator = url.match(/\?/) ? '&' : '?';
  return `${config.get('APP_URL')}${url}${paramSeparator}__ajax_request=1`;
}

function createApiUrl(url) {
  return `${config.get('API_URL')}${url}`;
}

function parseJSONResponse(res) {
  let response = res;

  if (typeof response === 'string') {
    try {
      response = JSON.parse(response);
    } catch (error) {
      // TODO
    }
  }

  return response;
}

function createQueryString(data) {
  if (!data) {
    return null;
  }

  return _.map(_.toPairs(data), (pair) => pair.map(encodeURIComponent).join('=')).join('&');
}

export function request(method, url, data, onUploadProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.withCredentials = true;

    xhr.onload = function () {
      const responseObject = parseJSONResponse(this.response);

      if (this.status >= 200 && this.status <= 206) {
        resolve(responseObject);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          statusCode: this.status,
          response: responseObject.error || this.response,
        });
      }
    };

    xhr.onerror = function (e) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        statusCode: e.target.status,
        response: e.target.response,
      });
    };

    if (typeof onUploadProgress === 'function') {
      xhr.upload.onprogress = onUploadProgress;
    }

    if (typeof FormData !== 'undefined' && data instanceof FormData) {
      xhr.send(data);
    } else {
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.send(createQueryString(data));
    }
  });
}

function jsonRequest(method, url, data) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.withCredentials = true;

    xhr.onload = function () {
      const responseObject = parseJSONResponse(this.response);

      if (this.status >= 200 && this.status <= 206) {
        resolve(responseObject);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          statusCode: this.status,
          response: responseObject.error || this.response,
        });
      }
    };

    xhr.onerror = function (e) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({
        statusCode: e.target.status,
        response: e.target.response,
      });
    };

    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(data);
  });
}

export function dmsRequest(method, url, data) {
  return jsonRequest(method, createDmsUrl(url), JSON.stringify(data))
    .then((response) => {
      if (response.error) {
        throw new Error(response.error);
      }

      // hack for checking if redirected to login page by DMS
      if (typeof response === 'string' && response.indexOf('id="login-form"') > -1) {
        throw new Error('permissions');
      }

      return response;
    });
}

export function apiRequest(method, url, data) {
  return request(method, createApiUrl(url), data);
}

export function get(url) {
  return request(METHOD_GET, url);
}

export function post(url, data, onUploadProgress) {
  return request(METHOD_POST, url, data, onUploadProgress);
}

export function dmsGet(url) {
  return dmsRequest(METHOD_GET, url);
}

export function dmsPost(url, data) {
  return dmsRequest(METHOD_POST, url, data);
}

export default Object.freeze({
  request,
  apiRequest,
  dmsRequest,
  get,
  post,
  dmsGet,
  dmsPost,
});
