import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  clearCache,
  triggerExportPrototype,
} from '../../controllers/actions/tools.actions';
import PrototypeActionsComponent from './PrototypeActions.component';

const mapStateToProps = (state, { prototype }) => {
  const idProject = prototype.id_project;

  return {
    isClearing: state.tools.cacheClearing,
    isCleared: state.tools.cacheCleared,
    isExporting: get(state.tools.exporting, idProject, false),
    exported: get(state.tools.exported, idProject, {}),
    exportError: get(state.tools.exportError, idProject, null),
  };
};

const mapDispatchToProps = (dispatch, { prototype }) => {
  const idProject = prototype.id_project;

  return {
    export: () => dispatch(triggerExportPrototype(idProject)),
    clearCache: () => dispatch(clearCache({ id_project: idProject })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrototypeActionsComponent);
