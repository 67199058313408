import { invert, isEmpty } from 'lodash';
import { searchActions, searchModes, searchModesUrlsMap } from '../../constants/search.constants';
import { getSavedQuery } from '../../helpers/querySaver';

const getInitialUrl = () => {
  if (isEmpty(window.location.hash)) {
    return searchModesUrlsMap[searchModes.ACCOUNT];
  }

  return window.location.pathname + window.location.hash;
};

const getInitialMode = () => invert(searchModesUrlsMap)[getInitialUrl()];

export function searchReducer(state = {
  activeUrl: getInitialUrl(),
  activeQuery: getSavedQuery(getInitialMode()),
  query: {
    [searchModes.ACCOUNT]: getSavedQuery(searchModes.ACCOUNT),
    [searchModes.PAGE]: getSavedQuery(searchModes.PAGE),
    [searchModes.PROTOTYPE]: getSavedQuery(searchModes.PROTOTYPE),
  },
  mode: getInitialMode(),
  results: [],
  page: {},
  prototype: {},
  isFetching: false,
}, action) {
  switch (action.type) {
    case searchActions.NAVIGATE:
      return Object.assign({}, state, {
        activeUrl: action.activeUrl,
      });
    case searchActions.SEARCH_SET_RESULTS:
      return Object.assign({}, state, {
        results: action.results,
      });
    case searchActions.SEARCH_SET_PAGE:
      return Object.assign({}, state, {
        page: action.results,
      });
    case searchActions.SEARCH_SET_PROTOTYPE:
      return Object.assign({}, state, {
        prototype: action.results,
      });
    case searchActions.SEARCH_SET_IS_FETCHING:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      });
    case searchActions.SEARCH_SET_MODE:
      return Object.assign({}, state, {
        mode: action.mode,
      });
    case searchActions.SEARCH_SET_QUERY:
      return Object.assign({}, state, {
        query: Object.assign({}, state.query, {
          [action.mode]: action.query,
        }),
      });
    default:
      return state;
  }
}
