import { connect } from 'react-redux';
import PageHistoryComponent from './PageHistory.component';
import { fetchList, fetchItem, toggleExpandItem } from '../../controllers/actions/pageHistory.actions';

export const mapStateToProps = (state) => {
  const { page } = state.search;
  const {
    isFetchingList, isFetchedList, list, items, listFilter,
  } = state.pageHistory;

  return {
    isFetchedList,
    isFetchingList,
    items,
    list,
    listFilter,
    page,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchList: (idPage, filter) => {
    dispatch(fetchList(idPage, filter));
  },
  fetchItem: (idPage, timestamp) => {
    dispatch(fetchItem(idPage, timestamp));
  },
  toggleExpandItem: (idPage, timestamp) => {
    dispatch(toggleExpandItem(idPage, timestamp));
  },
});

export const PageHistory = connect(mapStateToProps, mapDispatchToProps)(PageHistoryComponent);
