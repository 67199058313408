/* eslint-disable react/prop-types */
import React from 'react';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import ExternalLink from 'admin/images/external-link.svg';

export default class MenuItem extends React.Component {
  isMenuItemActive() {
    const { activeUrl, item: { url } } = this.props;
    return url === activeUrl;
  }

  @autobind
  handleClick() {
    const { searchSetMode, item, onNavigation } = this.props;

    if (!item.isExternal) {
      searchSetMode(item.searchMode);
      onNavigation(item.url);
    }
  }

  render() {
    const { item } = this.props;

    const classes = classnames('nav-menu-item', {
      active: this.isMenuItemActive(),
      external: item.isExternal,
    });

    const target = item.isExternal ? '_blank' : null;

    return (
      <li className={classes}>
        <a
          className={classnames(GLOBAL_ELEMENTS.LINK, 'menu-item-link')}
          href={item.url}
          onClick={this.handleClick}
          target={target}>
          {item.name}
          {item.isExternal
            ? <img className="external-icon" src={ExternalLink} alt="External link" />
            : null
          }
        </a>
      </li>
    );
  }
}
