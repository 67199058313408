import React from 'react';
import { noop } from 'lodash';
import { DataListItemTitle } from './DataListItemTitle';
import { DataListItem } from './DataListItem';

export function renderItemTitle(title) {
  return (
    <DataListItemTitle title={title} />
  );
}

export function renderItem(title, value) {
  return (
    <DataListItem title={title} value={value} />
  );
}

export function renderLink(title, value, options = {}) {
  const { anchorText = null, target = '_blank', onClick = noop } = options;

  return (
    <li>
      {renderItemTitle(title)}
      <a
        href={value}
        onClick={onClick}
        target={target}
        rel="noopener noreferrer">
        {anchorText || value}
      </a>
    </li>
  );
}
