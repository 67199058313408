import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ActionButton = ({ isWorking, onClick, label, dangerous }) => {
  const btnClassname = classnames('btn-solid btn-inline', {
    working: isWorking,
    red: dangerous,
  });

  return (
    <div className={btnClassname} onClick={onClick}>{label}</div>
  );
};

ActionButton.propTypes = {
  isWorking: PropTypes.bool,
  dangerous: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

ActionButton.defaultProps = {
  isWorking: false,
  dangerous: false,
};

export default ActionButton;
