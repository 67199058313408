import { adminsActions } from '../../constants/admins.constants';
import requestHelper from '../../helpers/requestHelper';
import config from 'react-global-configuration';

const SUPPORT_TOOLS_URL = config.get('SUPPORT_TOOLS_BACKEND_URL');

const setIsFetching = (isFetching) => ({
  type: adminsActions.ADMINS_SET_IS_FETCHING,
  isFetching,
});

const setIsFetchingMonitoring = (isFetchingMonitoring) => ({
  type: adminsActions.ADMINS_SET_IS_FETCHING_MONITORING,
  isFetchingMonitoring,
});

const setAdmins = (admins) => ({
  type: adminsActions.ADMINS_SET_ADMINS,
  admins,
});

const setMonitoring = (monitoring) => ({
  type: adminsActions.ADMINS_SET_MONITORING,
  monitoring,
});

const setError = (errorObject) => {
  const error = errorObject.statusCode
    ? `${errorObject.statusCode} - ${errorObject.response}`
    : errorObject;

  return {
    type: adminsActions.ADMINS_SET_ERROR,
    error,
  };
};

export const fetchMonitoring = () => (dispatch) => {
  dispatch(setIsFetchingMonitoring(true));

  requestHelper.get(`${SUPPORT_TOOLS_URL}/get_monitoring_log/`)
    .then((results) => {
      dispatch(setMonitoring(results));
    })
    .catch((error) => {
      dispatch(setError(error));
      dispatch(setIsFetchingMonitoring(false));
    });
};

export const fetchAdmins = () => (dispatch) => {
  dispatch(setIsFetching(true));

  requestHelper.get(`${SUPPORT_TOOLS_URL}/get_admins/`)
    .then((results) => {
      dispatch(setAdmins(results));
    })
    .catch((error) => {
      dispatch(setError(error));
      dispatch(setIsFetching(false));
    });
};

export const setAdmin = (data) => (dispatch) => {
  dispatch(setIsFetching(true));

  requestHelper.post(`${SUPPORT_TOOLS_URL}/set_admin/`, data)
    .then((results) => {
      dispatch(setAdmins(results));
      dispatch(fetchMonitoring());
    })
    .catch((error) => {
      dispatch(setError(error));
      dispatch(setIsFetching(false));
    });
};

export const unsetAdmin = (idUser) => (dispatch) => {
  dispatch(setIsFetching(true));

  requestHelper.post(`${SUPPORT_TOOLS_URL}/unset_admin/`, { id_dms_user: idUser })
    .then((results) => {
      dispatch(setAdmins(results));
    })
    .catch((error) => {
      dispatch(setError(error));
      dispatch(setIsFetching(false));
    });
};
