import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../Generic/ActionButton.component';

const ActionRemoveUser = ({ action, isWorking }) => {
  const handleClick = () => {
    if (!window.confirm('This operation is irreversible \n\nAre you sure you want to delete user?')) {
      return null;
    }

    action();
  };

  return (
    <ActionButton
      onClick={handleClick}
      isWorking={isWorking}
      label="Delete user"
      dangerous
    />
  );
};

ActionRemoveUser.propTypes = {
  action: PropTypes.func.isRequired,
  isWorking: PropTypes.bool,
};

ActionRemoveUser.defaultProps = {
  isWorking: false,
};

export default ActionRemoveUser;
