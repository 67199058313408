import { connect } from 'react-redux';
import { searchModes } from '../../constants/search.constants';
import SearchComponent from './Search.component';
import {
  searchFetch, searchFetchPageData, searchFetchPrototype, setQuery,
} from '../../controllers/actions/search.actions';

const mapModeToFetch = {
  [searchModes.ACCOUNT]: searchFetch,
  [searchModes.PAGE]: searchFetchPageData,
  [searchModes.PROTOTYPE]: searchFetchPrototype,
};

const mapModeToResultsUrl = {
  [searchModes.ACCOUNT]: '/search/',
  [searchModes.PAGE]: '/pages/',
  [searchModes.PROTOTYPE]: '/prototypes/',
};

const mapModeToPlaceholder = {
  [searchModes.ACCOUNT]: 'Search for email, id, url or invoice...',
  [searchModes.PAGE]: 'Search for page id...',
  [searchModes.PROTOTYPE]: 'Search for prototype by id, hash, editor url or preview url...',
};

export const mapStateToProps = (state) => {
  const { query, isFetching, mode } = state.search;

  return {
    query: query[mode],
    isFetching,
    mode,
    resultsUrl: mapModeToResultsUrl[mode],
    placeholder: mapModeToPlaceholder[mode],
  };
};

export const mapDispatchToProps = (dispatch) => ({
  searchFetch: (mode, query) => {
    dispatch(mapModeToFetch[mode](query));
  },
  setQuery: (mode, query) => {
    dispatch(setQuery(mode, query));
  },
});

export const Search = connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
