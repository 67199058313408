import React from 'react';
import ImportTool from './ImportTool.component';

const Tools = (props) => (
  <div className="main-content">
    <div className="content-container">
      <h1 className="title">Tools</h1>
      <ImportTool {...props} />
    </div>
  </div>
);

export default Tools;
