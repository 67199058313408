import { accountActions } from '../../constants/account.constants';

export function accountReducer(state = {
  data: {},
  isFetching: false,
}, action) {
  switch (action.type) {
    case accountActions.ACCOUNT_SET_DATA:
      return Object.assign({}, state, {
        data: action.data,
      });
    case accountActions.ACCOUNT_SET_IS_FETCHING:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      });
    default:
      return state;
  }
}
