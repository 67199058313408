import Cookies from 'js-cookie';

const cookieName = 'admin-queries';
const getQueryCookie = () => Cookies.getJSON(cookieName) || {};
const setQueryCookie = (value) => Cookies.set(cookieName, JSON.stringify(value));

export const saveQuery = (mode, query) => {
  setQueryCookie(Object.assign({}, getQueryCookie(), { [mode]: query }));
};

export const getSavedQuery = (mode) => getQueryCookie()[mode] || '';
