import { isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../Generic/Loader.component';
import { PrototypeData } from './PrototypeData.component';
import PrototypeActions from './PrototypeActions.container';

// eslint-disable-next-line react/prop-types
const Prototype = ({ isFetching, prototype, query }) => {
  const renderTitle = () => {
    let label;

    if (!isEmpty(prototype)) {
      label = 'Result';
    } else if (!isFetching && !isEmpty(query)) {
      label = 'Not found';
    }

    return label
      ? <h1 className="title">{label}</h1>
      : null;
  };

  const renderResults = () => {
    if (!isEmpty(prototype)) {
      return (
        <section>
          <PrototypeData data={prototype} />
          <PrototypeActions prototype={prototype} />
        </section>
      );
    }

    if (isFetching) {
      return <Loader />;
    }

    return null;
  };

  return (
    <div className="main-content">
      <div className="content-container">
        {renderTitle()}
        {renderResults()}
      </div>
    </div>
  );
};

Prototype.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  prototype: PropTypes.object,
  query: PropTypes.string,
};

Prototype.defaultProps = {
  prototype: {},
  query: '',
};

export default Prototype;
