/* eslint-disable react/prop-types,react/destructuring-assignment */
import React from 'react';
import autobind from 'autobind-decorator';
import { debounce, isEmpty } from 'lodash';
import config from 'react-global-configuration';
import LoadIndicator from 'admin/images/load-indicator.gif';


export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.triggerSearchDebounced = debounce(this.triggerSearch, 500);
  }

  componentDidMount() {
    if (this.input) {
      const { mode, query } = this.props;
      setTimeout(() => this.input.focus(), 100);

      if (!isEmpty(query)) {
        this.triggerSearch(mode, query);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.query !== this.props.query
      || nextProps.mode !== this.props.mode
    ) {
      this.triggerSearchDebounced(nextProps.mode, nextProps.query);
    }
  }

  @autobind
  setRef(e) {
    this.input = e;
  }

  triggerSearch(mode, query) {
    this.props.searchFetch(mode, query);
    this.showResults();
  }

  showResults() {
    const { resultsUrl } = this.props;
    window.location.hash = resultsUrl;
  }

  @autobind
  handleSearchSubmit(event) {
    event.preventDefault();
    const { mode, setQuery, query } = this.props;
    setQuery(mode, query);

    this.triggerSearchDebounced.cancel();
    this.triggerSearch(mode, query);
  }

  @autobind
  handleQueryChange(event) {
    event.preventDefault();
    const { mode, setQuery } = this.props;
    setQuery(mode, event.target.value);
  }

  render() {
    const { isFetching, query, placeholder } = this.props;

    return (
      <div className="search-container">
        <form
          className="input-wrapper icon-general-search"
          onSubmit={this.handleSearchSubmit}>
          <input
            ref={this.setRef}
            type="text"
            name="Search"
            className="invisible-input"
            placeholder={placeholder}
            value={query}
            onChange={this.handleQueryChange}
            autoComplete="off"
          />
          {isFetching
            ? (
              <img
                className="search-indicator"
                src={LoadIndicator}
                alt="Loading"
              />
            )
            : null
          }
        </form>
      </div>
    );
  }
}
