export const userActions = {
  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
  USER_SET_RESET_PASSWORD_RESULT: 'USER_SET_RESET_PASSWORD_RESULT',
  USER_SET_DATA: 'USER_SET_DATA',
  USER_SET_IS_FETCHING: 'USER_SET_IS_FETCHING',
  USER_SET_IS_RESETTING_PASSWORD: 'USER_SET_IS_RESETTING_PASSWORD',
  USER_SET_IS_UPDATING: 'USER_SET_IS_UPDATING',
  USER_SET_IS_REMOVING: 'USER_SET_IS_REMOVING',
  USER_SET_ERROR: 'USER_SET_ERROR',
};

export default userActions;
