export const adminsActions = {
  ADMINS_SET_IS_FETCHING: 'ADMINS_SET_IS_FETCHING',
  ADMINS_SET_IS_FETCHING_MONITORING: 'ADMINS_SET_IS_FETCHING_MONITORING',
  ADMINS_SET_ADMINS: 'ADMINS_SET_ADMINS',
  ADMINS_SET_MONITORING: 'ADMINS_SET_MONITORING',
  ADMINS_SET_ERROR: 'ADMINS_SET_ERROR',
};

export const adminRoles = {
  ADMINISTRATOR: 3,
  CUSTOMER: 4,
  SALES: 6,
  SUPERADMIN: 1,
};

export default adminsActions;
