import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminsComponent from './Admins.component';
import { fetchAdmins, fetchMonitoring, setAdmin, unsetAdmin } from '../../controllers/actions/admins.actions';

const mapStateToProps = (state) => {
  const { admins, monitoring, isFetching, isFetchingMonitoring, error } = state.admins;
  const { activeUserId } = state.login;

  return {
    admins,
    monitoring,
    isFetching,
    isFetchingMonitoring,
    error,
    activeUserId,
  };
};

const mapDispatchToProps = (dispatch) => (bindActionCreators({
  fetchAdmins,
  fetchMonitoring,
  setAdmin,
  unsetAdmin,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AdminsComponent);
