import React from 'react';
import DuckLoading from 'admin/images/duck-loading.gif';

const Loader = () => {
  const styles = {
    width: 300,
  };

  return (
    <div className="duck">
      <img src={DuckLoading} style={styles} alt="Loading" />
    </div>
  );
};

export default Loader;
