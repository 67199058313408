// extract library hash from a design system link:
export const getDesignSystemHashFromURL = (url) => {
  const hashPart = url.split('design-system/')[1];
  const hash = hashPart ? hashPart.split('/')[0] : null;

  return hash;
};

// a rudimentary email validator:
export const isValidEmail = (string) => {
  const atPosition = string.indexOf('@');
  const dotPosition = string.indexOf('.');
  const len = string.length;

  return (
    len > 5 // at least a@b.cd
    && atPosition > 0 && atPosition < (len - 3)
    && dotPosition > 0 && dotPosition < (len - 1)
  );
};

export const isValidHash = (hash) => (typeof hash === 'string' && hash.length === 20);

export const isValidDSName = (name) => (typeof name === 'string'); // can be empty

export const isValidForm = (fieldFlags) => (
  Object.values(fieldFlags).every((field) => (field === true))
);
