/* eslint-disable react/prop-types,react/destructuring-assignment */
import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import ActionButton from '../Generic/ActionButton.component';

const ExportActionItem = (props) => {
  const { export: actionExport, isExporting } = props;

  const renderButton = () => (
    <ActionButton onClick={actionExport} isWorking={isExporting} label="Export to file" />
  );

  const renderExportResult = () => {
    if (isEmpty(props.exported)) {
      return null;
    }

    const { url } = props.exported;

    return (
      <a className="export-result" href={url}>{url}</a>
    );
  };

  const renderExportError = () => {
    if (isEmpty(props.exportError)) {
      return null;
    }

    return (
      <span className="export-error">{props.exportError}</span>
    );
  };

  return (
    <li className="action-item">
      {renderButton()}
      {renderExportResult()}
      {renderExportError()}
    </li>
  );
};

const CacheClearItem = (props) => {
  const { clearCache, isClearing } = props;

  const renderButton = () => (
    <ActionButton onClick={clearCache} isWorking={isClearing} label="Clear prototype preview cache" />
  );

  const renderResult = () => {
    if (!props.isCleared) {
      return null;
    }

    return (
      <span className="cache-ok">OK</span>
    );
  };

  return (
    <li className="action-item">
      {renderButton()}
      {renderResult()}
    </li>
  );
};

const Actions = (props) => (
  <div>
    <h2 className="subtitle">Actions</h2>
    <ul className={classnames(GLOBAL_ELEMENTS.UNORDERED_LIST, 'actions-section')}>
      <ExportActionItem {...props} />
      <CacheClearItem {...props} />
    </ul>
  </div>
);

export default Actions;
