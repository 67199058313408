import config from 'react-global-configuration';

const { BASE_URL } = config.get();

export const searchActions = {
  NAVIGATE: 'NAVIGATE',
  SEARCH_FETCH: 'SEARCH_FETCH',
  SEARCH_SET_PAGE: 'SEARCH_SET_PAGE',
  SEARCH_SET_PROTOTYPE: 'SEARCH_SET_PROTOTYPE',
  SEARCH_SET_RESULTS: 'SEARCH_SET_RESULTS',
  SEARCH_SET_IS_FETCHING: 'SEARCH_SET_IS_FETCHING',
  SEARCH_SET_MODE: 'SEARCH_SET_MODE',
  SEARCH_SET_QUERY: 'SEARCH_SET_QUERY',
};

export const searchModes = {
  ACCOUNT: 'SEARCH_MODE_ACCOUNT',
  PAGE: 'SEARCH_MODE_PAGE',
  PROTOTYPE: 'SEARCH_MODE_PROTOTYPE',
};

export const searchModesUrlsMap = {
  [searchModes.ACCOUNT]: `${BASE_URL}#/search/`,
  [searchModes.PAGE]: `${BASE_URL}#/pages/`,
  [searchModes.PROTOTYPE]: `${BASE_URL}#/prototypes/`,
};

export default searchActions;
