/* eslint-disable react/prop-types,react/destructuring-assignment */
import _ from 'lodash';
import autobind from 'autobind-decorator';
import React from 'react';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import Loader from '../Generic/Loader.component';
import { DataListItem } from '../Generic/DataListItem';
import { DataListItemEditable } from '../Generic/DataListItemEditable';
import ActionResetPassword from './ActionResetPassword.component';
import ActionRemoveUser from './ActionRemoveUser.component';
import { searchModes } from '../../constants/search.constants';

export default class User extends React.Component {
  @autobind
  getIdUser() {
    const { match: { params: { idUser } } } = this.props;
    return idUser;
  }

  @autobind
  resetPassword() {
    const { userResetPassword } = this.props;
    const idUser = this.getIdUser();
    userResetPassword(idUser);
  }

  @autobind
  removeUser() {
    const { userRemove } = this.props;
    const idUser = this.getIdUser();
    userRemove(idUser);
  }

  @autobind
  updateEmail(value) {
    const { userUpdate, match: { params: { idUser } } } = this.props;
    userUpdate(idUser, { email: value });
  }

  @autobind
  componentWillMount() {
    const { data, userFetch } = this.props;
    const idUser = this.getIdUser();

    if (_.isEmpty(data) || data.id_dms_user !== idUser) {
      userFetch(idUser);
    }
  }

  @autobind
  navigateToAccountSearch(e) {
    e.preventDefault();
    const { navigateToSearchMode } = this.props;
    navigateToSearchMode(searchModes.ACCOUNT);
  }

  @autobind
  renderError() {
    const { error } = this.props;

    if (!error) {
      return null;
    }

    const errorString = error.statusCode
      ? `${error.statusCode} - ${error.response}`
      : error;

    return (
      <p className="error-line">{errorString}</p>
    );
  }

  @autobind
  renderDuck() {
    const { isFetching } = this.props;

    if (!isFetching) {
      return null;
    }

    return (<Loader />);
  }

  @autobind
  renderUserData() {
    const { data, isFetching, isUpdating, isResettingPassword, isRemoving, resetPasswordResult } = this.props;

    if (_.isEmpty(data)) {
      return !isFetching && (<h2>Not found</h2>);
    }

    const {
      id_dms_user: idUser,
      email,
      firstname,
      lastname,
      last_login_date: lastLoginDate,
      can_delete: canDelete,
    } = data;

    if (idUser !== this.getIdUser()) {
      return null;
    }

    return (
      <div className="data-section">
        <h1 className="title">User</h1>
        {this.renderError()}

        <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
          <DataListItem title="First name:" value={firstname} />
          <DataListItem title="Last name:" value={lastname} />
          <DataListItemEditable
            key={`${idUser}-email`}
            title="Email:"
            value={email}
            onUpdate={this.updateEmail}
            working={isUpdating}
          />
          <DataListItem title="Last login date:" value={lastLoginDate} />
        </ul>

        <h2 className="subtitle section-title">Actions</h2>
        <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
          <li className="user-action-item">
            <ActionResetPassword
              action={this.resetPassword}
              isWorking={isResettingPassword}
              result={resetPasswordResult}
            />
          </li>
        </ul>

        <h2 className="subtitle section-title">Removing</h2>
        {canDelete ? (
          <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
            <li className="user-action-item">
              <ActionRemoveUser
                action={this.removeUser}
                isWorking={isRemoving}
              />
            </li>
          </ul>
        ) : (
          <p>This user cannot be deleted due to account(s) connection(s).</p>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="main-content">
        <div className="content-container">
          <p className="back-navigation">
            <a href="#" onClick={this.navigateToAccountSearch}>← Results</a>
          </p>

          {this.renderUserData()}
          {this.renderDuck()}
        </div>
      </div>
    );
  }
}
