/* eslint-disable react/prop-types */
import React from 'react';
import { render } from 'react-dom';
import { Provider, connect } from 'react-redux';
import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import './initConfig'; // this must be first file imported after external libs for proper config initialization
import { Menu } from './views/Menu/Menu.container';
import TopBar from './views/TopBar/TopBar.container';
import Account from './views/Account/Account.container';
import User from './views/User/User.container';
import { SearchResults } from './views/Search/SearchResults.container';
import { Page } from './views/Page/Page.container';
import { Prototype } from './views/Prototype/Prototype.container';
import Tools from './views/Tools/Tools.container';
import { DesignSystems } from './views/DesignSystems/DesignSystems.container';
import Admins from './views/Admins/Admins.container';
import { appStore } from './controllers/app';
import { loginFetch } from './controllers/actions/login.actions';
import config from 'react-global-configuration';

const Authenticating = () => (
  <div id="admin-panel">
    <div className="authenticating-overlay">
      <h3 className="label">Authenticating ...</h3>
    </div>
  </div>
);

class App extends React.Component {
  handleLogin() {
    const {
      fetchAuthorization,
      hasFetchedAuthorization,
      isAuthorized,
      isFetchingAuthorization,
    } = this.props;

    if (!isAuthorized) {
      if (hasFetchedAuthorization) {
        window.location.href = config.get('APP_URL');
      } else if (!isFetchingAuthorization) {
        fetchAuthorization();
      }
    }
  }

  render() {
    this.handleLogin();

    // eslint-disable-next-line react/destructuring-assignment
    if (!this.props.isAuthorized) {
      return (<Authenticating />);
    }

    return (
      <div id="admin-panel">
        <TopBar />
        <Menu />
        <Router>
          <Switch>
            <Route path="/search/" component={SearchResults} />
            <Route path="/pages/" component={Page} />
            <Route path="/prototypes/" component={Prototype} />
            <Route path="/tools/" component={Tools} />
            <Route path="/design-systems/" component={DesignSystems} />
            <Route path="/account/:idAccount/" component={Account} />
            <Route path="/user/:idUser/" component={User} />
            <Route path="/admins/" component={Admins} />
          </Switch>
        </Router>
        <div className="baseline" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hasFetchedAuthorization: state.login.hasFetchedAuthorization,
  isAuthorized: state.login.isAuthorized,
  isFetchingAuthorization: state.login.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAuthorization: () => {
    dispatch(loginFetch());
  },
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

render(
  <Provider store={appStore}>
    <AppContainer />
  </Provider>,
  document.getElementById('admin-container')
);
