import { connect } from 'react-redux';
import DesignSystemsComponent from './DesignSystems.component';
import { duplicateDesignSystem, checkDesignSystemStatus } from '../../controllers/actions/designSystems.actions';

export const mapStateToProps = (state) => {
  const { duplicationResponse, isStartingDuplication, statusResponse } = state.designSystems;

  return {
    duplicationResponse,
    isStartingDuplication,
    statusResponse,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  duplicateDesignSystem: (data) => dispatch(duplicateDesignSystem(data)),
  checkDesignSystemStatus: (idDesignSystem) => {
    dispatch(checkDesignSystemStatus(idDesignSystem));
  },
});

export const DesignSystems = connect(mapStateToProps, mapDispatchToProps)(DesignSystemsComponent);
