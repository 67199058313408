export const toolsActions = {
  PROTOTYPE_EXPORT: 'PROTOTYPE_EXPORT',
  PROTOTYPE_IMPORTING: 'PROTOTYPE_IMPORTING',
  PROTOTYPE_IMPORTED: 'PROTOTYPE_IMPORTED',
  PROTOTYPE_IMPORT_ERROR: 'PROTOTYPE_IMPORT_ERROR',
  CACHE_CLEARING: 'CACHE_CLEARING',
  CACHE_CLEARED: 'CACHE_CLEARED',
};

export default toolsActions;
