import { connect } from 'react-redux';
import { pick } from 'lodash';
import MenuComponent from './Menu.component';
import { CONFIG } from '../../config/modules';

export const mapStateToProps = (state) => {
  const { activeModules } = state.login;
  const modules = pick(CONFIG, activeModules);

  return { modules };
};

export const Menu = connect(mapStateToProps)(MenuComponent);
