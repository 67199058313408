import React from 'react';
import PropTypes from 'prop-types';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { MetadataSection } from '../Generic/MetadataSection.component';
import PageContentJson from '../Generic/PageContentJson.component';
import { renderItemTitle, renderItem, renderLink } from '../Generic/renderItem';
import config from 'react-global-configuration';

const SUPPORT_TOOLS_URL = config.get('SUPPORT_TOOLS_BACKEND_URL');

export const PageMetadata = ({ data, navigateToPrototype }) => {
  const isComponent = !!data.component_version;

  const handleNavigateToPrototype = (event) => {
    event.preventDefault();
    navigateToPrototype(data.project.id_project);
  };

  const renderContent = (title, value) => (
    <li>
      {renderItemTitle(title)}
      <div className="item-content">
        <PageContentJson value={value} />
      </div>
    </li>
  );

  const renderBasicData = (pageData) => (
    <MetadataSection>
      <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
        {renderItem('ID:', pageData.id_page)}
        {renderItem('Name:', pageData.name)}
        {renderItem('Last update:', pageData.last_update)}
        {!isComponent && renderLink('Preview:', pageData.preview_url)}
        {isComponent && renderItem('Is component:', 'YES')}
      </ul>
    </MetadataSection>
  );

  const renderPrototypeData = (pageData) => (
    <MetadataSection>
      <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
        {renderLink('Prototype ID:', pageData.project.id_project, { onClick: handleNavigateToPrototype })}
        {renderItem('Prototype Name:', pageData.project.name)}
      </ul>
    </MetadataSection>
  );

  const renderBreakpointsData = (pageData) => (
    pageData.variation_data && (
      <MetadataSection>
        <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
          {renderItem('Breakpoint name:', pageData.variation_data.text)}
          {renderItem('Breakpoint size:', `${pageData.variation_data.width}x${pageData.variation_data.height}`)}
        </ul>
      </MetadataSection>
    )
  );

  const renderContentData = (pageData) => {
    const { id_page: idPage, content } = pageData;
    const downloadLink = `${SUPPORT_TOOLS_URL}/get_page_content/?id_page=${idPage}&file=1`;
    const downloadLinkOptions = {
      anchorText: `${idPage}.json`,
      target: '',
    };

    return (
      <MetadataSection title="Content">
        <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
          {renderLink('Download:', downloadLink, downloadLinkOptions)}
          {renderContent('JSON preview:', content)}
        </ul>
      </MetadataSection>
    );
  };

  return (
    <div>
      {renderBasicData(data)}
      {renderPrototypeData(data)}
      {!isComponent && renderBreakpointsData(data)}
      {renderContentData(data)}
    </div>
  );
};

PageMetadata.propTypes = {
  data: PropTypes.shape({
    id_page: PropTypes.string.isRequired,
    content: PropTypes.object,
    variation_data: PropTypes.object,
    project: PropTypes.shape({
      id_project: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  }),
  navigateToPrototype: PropTypes.func.isRequired,
};

PageMetadata.defaultProps = {
  data: {
    content: '',
  },
};
