import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import autobind from 'autobind-decorator';
import { DataListItemTitle } from './DataListItemTitle';

export class DataListItemEditable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      value: props.value,
    };
  }

  @autobind
  enableEditing() {
    this.setState({ editing: true });
  }

  disableEditing() {
    this.setState({ editing: false });
  }

  @autobind
  handleUpdate(e) {
    this.setState({ value: e.target.value });
  }

  @autobind
  saveUpdate() {
    const { onUpdate } = this.props;
    const { value } = this.state;

    this.disableEditing();
    onUpdate(value);
  }

  renderStatic() {
    const { working } = this.props;
    const { value } = this.state;

    return (
      <Fragment>
        {value}
        <input
          type="button"
          value="Edit"
          onClick={this.enableEditing}
          className="edit-inline-input"
          disabled={working}
        />
      </Fragment>
    );
  }

  renderEditing() {
    const { value } = this.state;

    return (
      <Fragment>
        <input type="text" value={value} onChange={this.handleUpdate} className="edit-inline-input-text" />
        <input type="button" value="Save" onClick={this.saveUpdate} className="edit-inline-input" />
      </Fragment>
    );
  }

  render() {
    const { title } = this.props;
    const { editing } = this.state;

    return (
      <li>
        <DataListItemTitle title={title} />
        {editing ? this.renderEditing() : this.renderStatic()}
      </li>
    );
  }
}

DataListItemEditable.propTypes = {
  onUpdate: PropTypes.func,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  working: PropTypes.bool,
};

DataListItemEditable.defaultProps = {
  onUpdate: noop,
  working: false,
  value: '',
};
