import { isEmpty } from 'lodash';
import { handleLoginError } from './login.actions';
import { searchActions, searchModesUrlsMap } from '../../constants/search.constants';
import requestHelper from '../../helpers/requestHelper';
import { saveQuery } from '../../helpers/querySaver';
import { resetPageId } from './pageHistory.actions';
import config from 'react-global-configuration';


const SUPPORT_TOOLS_URL = config.get('SUPPORT_TOOLS_BACKEND_URL');

export const navigate = (url) => ({
  type: searchActions.NAVIGATE,
  activeUrl: url,
});

export const setQuery = (mode, query) => {
  saveQuery(mode, query);

  return {
    type: searchActions.SEARCH_SET_QUERY,
    mode,
    query,
  };
};

export const searchSetResults = (results) => ({
  type: searchActions.SEARCH_SET_RESULTS,
  results,
});

export const searchSetPage = (results) => ({
  type: searchActions.SEARCH_SET_PAGE,
  results,
});

export const searchSetPrototype = (results) => ({
  type: searchActions.SEARCH_SET_PROTOTYPE,
  results,
});

export const searchSetMode = (mode) => ({
  type: searchActions.SEARCH_SET_MODE,
  mode,
});

export const navigateToSearchMode = (mode, query) => (dispatch) => {
  const url = searchModesUrlsMap[mode];

  if (query) {
    dispatch(setQuery(mode, query));
  }

  dispatch(searchSetMode(mode));
  dispatch(navigate(url));
  window.location.href = url;
};

export const searchSetIsFetching = (isFetching) => ({
  type: searchActions.SEARCH_SET_IS_FETCHING,
  isFetching,
});

export const searchFetch = (query) => (dispatch) => {
  if (isEmpty(query)) {
    dispatch(searchSetResults([]));
    return;
  }

  dispatch(searchSetIsFetching(true));
  requestHelper.dmsPost('/ajax/dmsAdminSearch/GetData/', {
    email: query,
  })
    .then((results) => {
      dispatch(searchSetIsFetching(false));
      dispatch(searchSetResults(results));
    })
    .catch((error) => {
      handleLoginError(dispatch, error);

      dispatch(searchSetIsFetching(false));
      dispatch(searchSetResults([]));
    });
};

export const searchFetchPageData = (idPage) => (dispatch) => {
  dispatch(resetPageId(idPage));

  if (isEmpty(idPage)) {
    dispatch(searchSetPage({}));
    return;
  }

  dispatch(searchSetIsFetching(true));

  requestHelper.get(`${SUPPORT_TOOLS_URL}/get_page/?id_page=${idPage}`)
    .then((results) => {
      dispatch(searchSetIsFetching(false));
      dispatch(searchSetPage(results));
    })
    .catch((error) => {
      handleLoginError(dispatch, error);

      dispatch(searchSetIsFetching(false));
      dispatch(searchSetPage({}));
    });
};


// Prepares querystring from prototype search query
//
// Possible values for query:
// project id: 12345678
// project hash: abcdef0123456789abcdef0123456789abcdef01
// editor url: https://app.uxpin.com/edit/12345678#?id_page=12345678
// eslint-disable-next-line max-len
// preview url: https://preview.uxpin.com/abcdef0123456789abcdef0123456789abcdef01#/pages/12345678/simulate/no-panels?mode=i
//
// Returns querystring in format: "id_project=..." or "hash=..."
//
// @param {string} query
// @returns {string}

const getQueryStringFromPrototypeSearch = (query) => {
  // eslint-disable-next-line no-restricted-globals
  if (isFinite(query)) {
    return `id_project=${query}`;
  }

  const editorUrlMatchPattern = /\/edit\/(\d+)/;
  const editorMatch = query.match(editorUrlMatchPattern);

  if (editorMatch && editorMatch[1]) {
    return `id_project=${editorMatch[1]}`;
  }

  const previewUrlMatchPattern = /[a-f0-9]{40}/;
  const previewMatch = query.match(previewUrlMatchPattern);

  if (previewMatch && previewMatch[0]) {
    return `hash=${previewMatch[0]}`;
  }

  return `hash=${query}`;
};

export const searchFetchPrototype = (query) => (dispatch) => {
  if (isEmpty(query)) {
    dispatch(searchSetPrototype({}));
    return;
  }

  const queryString = getQueryStringFromPrototypeSearch(query);

  dispatch(searchSetIsFetching(true));
  requestHelper.get(`${SUPPORT_TOOLS_URL}/get_prototype/?${queryString}`)
    .then((results) => {
      dispatch(searchSetIsFetching(false));
      dispatch(searchSetPrototype(results || {}));
    })
    .catch((error) => {
      handleLoginError(dispatch, error);

      dispatch(searchSetIsFetching(false));
      dispatch(searchSetPrototype({}));
    });
};
