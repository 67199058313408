import { connect } from 'react-redux';
import Tools from './Tools.component';

import {
  setImportError,
  triggerImportPrototype,
} from '../../controllers/actions/tools.actions';

const mapStateToProps = (state) => ({
  imported: state.tools.prototypeImported,
  importError: state.tools.prototypeImportError,
  isImporting: state.tools.prototypeImporting,
});

const mapDispatchToProps = (dispatch) => ({
  importPrototype: (formData) => {
    dispatch(triggerImportPrototype(formData));
  },
  setImportError: (error) => {
    dispatch(setImportError(error));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
