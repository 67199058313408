import React from 'react';
import PropTypes from 'prop-types';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { MetadataSection } from '../Generic/MetadataSection.component';
import PageHistoryItem from './PageHistoryItem.component';
import formatRangeTimestamp from '../../helpers/formatRangeTimestamp';
import PageHistoryForm from './PageHistoryForm.component';

const PageHistory = (props) => {
  const {
    fetchItem,
    fetchList,
    isFetchedList,
    items,
    list,
    page,
    toggleExpandItem,
  } = props;

  const hasHistoryRecords = () => (
    page.content_history && page.content_history.count > 0
  );

  const onFilterSubmit = (filter) => {
    fetchList(page.id_page, filter);
  };

  const renderFetchList = () => {
    if (!hasHistoryRecords()) {
      return null;
    }

    const { isFetchingList, listFilter } = props;

    return (
      <PageHistoryForm
        isLoading={isFetchingList}
        listFilter={listFilter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  const renderHistoryInfo = () => {
    const { count, start, end } = page.content_history;

    return (
      <p>{hasHistoryRecords()
        ? `${count} records (from ${formatRangeTimestamp(start)}, to ${formatRangeTimestamp(end)})`
        : 'No history records'}
      </p>
    );
  };

  const renderList = () => {
    if (!isFetchedList || !hasHistoryRecords()) {
      return null;
    }

    if (!list.length) {
      return <p>Empty</p>;
    }

    return (
      <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
        {list.map((item) => (
          <PageHistoryItem
            key={item.timestamp}
            itemMeta={item}
            itemData={items[item.timestamp]}
            fetchItem={fetchItem}
            toggleExpandItem={toggleExpandItem}
          />
        ))}
      </ul>
    );
  };

  return (
    <MetadataSection title="Edits history">
      {renderHistoryInfo()}
      {renderFetchList()}
      {renderList()}
    </MetadataSection>
  );
};

PageHistory.propTypes = {
  fetchItem: PropTypes.func.isRequired,
  fetchList: PropTypes.func.isRequired,
  isFetchedList: PropTypes.bool.isRequired,
  isFetchingList: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  items: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  listFilter: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  page: PropTypes.object.isRequired,
  toggleExpandItem: PropTypes.func.isRequired,
};

export default PageHistory;
