import React from 'react';
import PropTypes from 'prop-types';
import { DataListItemTitle } from './DataListItemTitle';

export function DataListItem({ title, value }) {
  return (
    <li>
      <DataListItemTitle title={title} />
      {value}
    </li>
  );
}

DataListItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

DataListItem.defaultProps = {
  value: '',
};
