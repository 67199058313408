import { designSystemsActions } from '../../constants/designSystems.constants';

export function designSystemsReducer(state = {
  duplicationResponse: {},
  statusResponse: {},
  isStartingDuplication: false,
}, action) {
  switch (action.type) {
    case designSystemsActions.DESIGN_SYSTEM_DUPLICATE_START:
      return Object.assign({}, state, {
        isStartingDuplication: action.isStartingDuplication,
      });
    case designSystemsActions.DESIGN_SYSTEM_DUPLICATE:
      return Object.assign({}, state, {
        duplicationResponse: action.duplicationResponse,
        isStartingDuplication: action.isStartingDuplication,
      });
    case designSystemsActions.DESIGN_SYSTEM_STATUS:
      return Object.assign({}, state, {
        statusResponse: action.statusResponse,
      });
    default:
      return state;
  }
}
