import React from 'react';
import PropTypes from 'prop-types';

export const MetadataSection = ({ title, children }) => {
  const renderTitle = () => (
    title && (<h2>{title}</h2>)
  );

  return (
    <section className="metadata-section">
      {renderTitle()}
      {children}
    </section>
  );
};

MetadataSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

MetadataSection.defaultProps = {
  title: '',
};
