import { get } from 'lodash';
import moment from 'moment';
import { pageHistoryActions, rangeFilter, sortFilter } from '../../constants/pageHistory.constants';

function getDefaultListFilter() {
  return {
    range: rangeFilter.RANGE_24H,
    rangeStartDate: moment().format('YYYY-MM-DD'),
    rangeStartTime: '00:00',
    rangeEndDate: moment().format('YYYY-MM-DD'),
    rangeEndTime: '23:59',
    sort: sortFilter.SORT_DESC,
  };
}

export default function pageHistoryReducer(state = {
  idPage: null,
  isFetchedList: false,
  isFetchingList: false,
  items: {},
  list: [],
  listFilter: getDefaultListFilter(),
}, action) {
  switch (action.type) {
    case pageHistoryActions.HISTORY_PAGEID_RESET:
      if (action.idPage === state.idPage) {
        return state;
      }

      return Object.assign({}, state, {
        idPage: action.idPage,
        list: [],
        items: {},
        isFetchedList: false,
        listFilter: getDefaultListFilter(),
      });
    case pageHistoryActions.HISTORY_LIST_FETCH:
      return Object.assign({}, state, {
        isFetchingList: action.isFetchingList,
      });
    case pageHistoryActions.HISTORY_LIST_SET:
      return Object.assign({}, state, {
        isFetchingList: action.isFetchingList,
        isFetchedList: action.isFetchedList,
        list: action.list,
      });
    case pageHistoryActions.HISTORY_ITEM_SET:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.data.timestamp]: Object.assign({}, get(state.items, action.data.timestamp, {}), action.data),
        }),
      });
    case pageHistoryActions.HISTORY_ITEM_TOGGLE:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.timestamp]: Object.assign({}, get(state.items, action.timestamp, {}), {
            id_page: action.idPage,
            isExpanded: !get(state.items, [action.timestamp, 'isExpanded'], false),
          }),
        }),
      });
    case pageHistoryActions.HISTORY_LIST_FILTER_SET:
      return Object.assign({}, state, {
        listFilter: Object.assign({}, state.listFilter, action.filter),
      });
    default:
      return state;
  }
}
