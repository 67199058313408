import { toolsActions } from '../../constants/tools.constants';

export function toolsReducer(state = {
  cacheClearing: false,
  cacheCleared: false,
  exporting: {},
  exported: {},
  exportError: {},
  prototypeImporting: false,
  prototypeImportError: null,
  prototypeImportedUrl: null,
}, action) {
  switch (action.type) {
    case toolsActions.PROTOTYPE_EXPORT:
      return Object.assign({}, state, {
        exporting: Object.assign({}, state.exporting, {
          [action.idProject]: action.isExporting,
        }),
        exported: Object.assign({}, state.exported, {
          [action.idProject]: action.result,
        }),
        exportError: Object.assign({}, state.exportError, {
          [action.idProject]: action.error,
        }),
      });

    case toolsActions.PROTOTYPE_IMPORTING:
      return Object.assign({}, state, {
        prototypeImporting: true,
        prototypeImportError: null,
        prototypeImportedUrl: null,
      });
    case toolsActions.PROTOTYPE_IMPORTED:
      return Object.assign({}, state, {
        prototypeImporting: false,
        prototypeImportError: null,
        prototypeImported: action.prototype,
      });
    case toolsActions.PROTOTYPE_IMPORT_ERROR:
      return Object.assign({}, state, {
        prototypeImporting: false,
        prototypeImportError: action.error,
        prototypeImportedUrl: null,
      });

    case toolsActions.CACHE_CLEARING:
      return Object.assign({}, state, {
        cacheClearing: action.clearing,
      });
    case toolsActions.CACHE_CLEARED:
      return Object.assign({}, state, {
        cacheCleared: action.cleared,
      });
    default:
      return state;
  }
}
