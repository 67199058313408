import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import ActionButton from '../Generic/ActionButton.component';

const ActionResetPassword = ({ action, isWorking, result }) => (
  <div>
    <ActionButton onClick={action} isWorking={isWorking} label="Get password reset link" />
    {!isEmpty(result) && <input type="text" value={result} className="reset-password-link-input" />}
  </div>
);

ActionResetPassword.propTypes = {
  action: PropTypes.func.isRequired,
  isWorking: PropTypes.bool,
  result: PropTypes.string,
};

ActionResetPassword.defaultProps = {
  isWorking: false,
  result: '',
};

export default ActionResetPassword;
