import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { filter, isEmpty, some } from 'lodash';
import config from 'react-global-configuration';

const { BASE_URL } = config.get();

const tableSettings = {
  minRows: 1,
  className: 'custom-table',
  showPagination: false,
  showPageSizeOptions: false,
};

const ACCESSORS = {
  ID: 'id_dms_user',
  EMAIL: 'email',
  FIRSTNAME: 'firstname',
  LASTNAME: 'lastname',
  ROLE: 'role_name',
  LAST_VISIT: 'last_visit',
  ACTIONS: 'id_dms_user',
};

/* eslint-disable react/prop-types */
const textCell = ({ value }) => (<span>{value}</span>);
const userEmailCell = ({ value, original: { id_dms_user: idUser } }) => (
  <a href={`${BASE_URL}#/user/${idUser}`}>{value}</a>
);
/* eslint-enable */

const COLUMNS = {
  [ACCESSORS.EMAIL]: { Header: 'Email', Cell: userEmailCell, accessor: ACCESSORS.EMAIL },
  [ACCESSORS.FIRSTNAME]: { Header: 'Firstname', Cell: textCell, accessor: ACCESSORS.FIRSTNAME },
  [ACCESSORS.LASTNAME]: { Header: 'Lastname', Cell: textCell, accessor: ACCESSORS.LASTNAME },
  [ACCESSORS.ID]: { Header: 'Id', Cell: textCell, accessor: ACCESSORS.ID },
  [ACCESSORS.ROLE]: { Header: 'Role', Cell: textCell, accessor: ACCESSORS.ROLE },
  [ACCESSORS.LAST_VISIT]: { Header: 'Last login', Cell: textCell, accessor: ACCESSORS.LAST_VISIT },
};

const getColumns = (users) => (
  filter(Object.values(COLUMNS), (column) => some(users, (user) => !isEmpty(user[column.accessor])))
);

const AccountUsers = ({ users }) => {
  const { className, minRows, showPagination, showPageSizeOptions } = tableSettings;

  return (
    <ReactTable
      data={users}
      className={className}
      columns={getColumns(users)}
      minRows={minRows}
      showPagination={showPagination}
      showPageSizeOptions={showPageSizeOptions}
    />
  );
};

AccountUsers.propTypes = {
  users: PropTypes.array.isRequired,
};

export default AccountUsers;
