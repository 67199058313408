import { loginActions } from '../../constants/login.constants';

export default function loginReducer(state = {
  activeUserId: null,
  activeModules: [],
  hasFetchedAuthorization: false,
  isAuthorized: false,
  isFetching: false,
}, action) {
  switch (action.type) {
    case loginActions.LOGIN_SET_IS_AUTHORIZED: {
      const { isAuthorized, isFetching, hasFetchedAuthorization } = action;
      return {
        ...state,
        isAuthorized,
        isFetching,
        hasFetchedAuthorization,
      };
    }
    case loginActions.LOGIN_SET_IS_FETCHING: {
      const { isFetching, hasFetchedAuthorization } = action;
      return {
        ...state,
        isFetching,
        hasFetchedAuthorization,
      };
    }
    case loginActions.LOGIN_SET_ACTIVE_MODULES: {
      const { activeModules } = action;
      return {
        ...state,
        activeModules,
      };
    }
    case loginActions.LOGIN_SET_ACTIVE_USER_ID: {
      const { activeUserId } = action;
      return {
        ...state,
        activeUserId,
      };
    }
    default:
      return state;
  }
}
