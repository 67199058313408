import { connect } from 'react-redux';
import { searchModes } from '../../constants/search.constants';
import SearchResultsComponent from './SearchResults.component';

export const mapStateToProps = (state) => {
  const { query, results, isFetching } = state.search;

  return {
    query: query[searchModes.ACCOUNT],
    results,
    isFetching,
  };
};

export const SearchResults = connect(mapStateToProps)(SearchResultsComponent);
