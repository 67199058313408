import { pageHistoryActions } from '../../constants/pageHistory.constants';
import { handleLoginError } from './login.actions';
import requestHelper from '../../helpers/requestHelper';
import config from 'react-global-configuration';


const SUPPORT_TOOLS_URL = config.get('SUPPORT_TOOLS_BACKEND_URL');

export const resetPageId = (idPage) => ({
  type: pageHistoryActions.HISTORY_PAGEID_RESET,
  idPage,
});

export const setFetchingList = (isFetchingList) => ({
  type: pageHistoryActions.HISTORY_LIST_FETCH,
  isFetchingList,
});

export const setList = (list) => ({
  type: pageHistoryActions.HISTORY_LIST_SET,
  isFetchingList: false,
  isFetchedList: true,
  list,
});

export const setListFilter = (filter) => ({
  type: pageHistoryActions.HISTORY_LIST_FILTER_SET,
  filter,
});

export const setItem = (data) => ({
  type: pageHistoryActions.HISTORY_ITEM_SET,
  data,
});

export const toggleExpandItem = (idPage, timestamp) => ({
  type: pageHistoryActions.HISTORY_ITEM_TOGGLE,
  idPage,
  timestamp,
});

export const fetchList = (idPage, filter) => (dispatch) => {
  dispatch(setFetchingList(true));
  dispatch(setListFilter(filter));

  requestHelper.post(`${SUPPORT_TOOLS_URL}/get_page_history/?id_page=${idPage}`, filter)
    .then((results) => {
      dispatch(setList(results));
    })
    .catch((error) => {
      handleLoginError(dispatch, error);
      dispatch(setList([]));
    });
};

export const fetchItem = (idPage, timestamp) => (dispatch) => {
  dispatch(setItem({ id_page: idPage, timestamp, isFetching: true, isExpanded: true }));

  requestHelper.get(`${SUPPORT_TOOLS_URL}/get_page_history_record/?id_page=${idPage}&timestamp=${timestamp}`)
    .then((results) => {
      dispatch(setItem(Object.assign({}, results, { isFetching: false })));
    })
    .catch((error) => {
      handleLoginError(dispatch, error);
      dispatch(setItem({
        id_page: idPage, timestamp, isFetching: false, update: `Error: ${error}`,
      }));
    });
};
