import { loginFetch } from './login.actions';
import { accountActions } from '../../constants/account.constants';
import requestHelper from '../../helpers/requestHelper';
import { logError, errorMessage } from '../../helpers/errorHandler';
import config from 'react-global-configuration';

const SUPPORT_TOOLS_URL = config.get('SUPPORT_TOOLS_BACKEND_URL');

export const accountSetResults = (data) => ({
  type: accountActions.ACCOUNT_SET_DATA,
  data,
});

export const accountSetIsFetching = (isFetching) => ({
  type: accountActions.ACCOUNT_SET_IS_FETCHING,
  isFetching,
});

export const accountFetch = (idAccount) => (dispatch) => {
  dispatch(accountSetIsFetching(true));
  dispatch(accountSetResults({}));

  requestHelper.get(`${SUPPORT_TOOLS_URL}/get_account/?id_account=${idAccount}`)
    .then((data) => {
      dispatch(accountSetIsFetching(false));
      dispatch(accountSetResults(data));
    })
    .catch((error) => {
      if (errorMessage(error) === 'permissions') {
        dispatch(loginFetch());
      } else {
        logError(error);
      }
    });
};
