import config from 'react-global-configuration';
import { searchModes, searchModesUrlsMap } from '../constants/search.constants';
import * as MODULES from '../constants/modules.constants';

const { APP_URL, BASE_URL } = config.get();

export const CONFIG = {
  [MODULES.SEARCH]: {
    name: 'Search',
    url: searchModesUrlsMap[searchModes.ACCOUNT],
    searchMode: searchModes.ACCOUNT,
  },
  [MODULES.PAGES]: {
    name: 'Pages',
    url: searchModesUrlsMap[searchModes.PAGE],
    searchMode: searchModes.PAGE,
  },
  [MODULES.PROTOTYPES]: {
    name: 'Prototypes',
    url: searchModesUrlsMap[searchModes.PROTOTYPE],
    searchMode: searchModes.PROTOTYPE,
  },
  [MODULES.TOOLS]: {
    name: 'Tools',
    url: `${BASE_URL}#/tools/`,
  },
  [MODULES.DESIGN_SYSTEMS]: {
    name: 'Design systems',
    url: `${BASE_URL}#/design-systems/`,
  },
  [MODULES.ADMINS]: {
    name: 'Admins',
    url: `${BASE_URL}#/admins/`,
  },
  [MODULES.ACCOUNTS]: {
    name: 'Accounts',
    url: `${APP_URL}/dms/dmsAccounts/Default/`,
    isExternal: true,
  },
  [MODULES.INVOICES]: {
    name: 'Invoices',
    url: `${APP_URL}/dms/dmsInvoices/Default/`,
    isExternal: true,
  },
  [MODULES.PLANS]: {
    name: 'Plans',
    url: `${APP_URL}/dms/DPPlans/Default/`,
    isExternal: true,
  },
  [MODULES.EMAILS]: {
    name: 'Emails',
    url: `${APP_URL}/dms/TextParts/Default/Email/`,
    isExternal: true,
  },
  [MODULES.STORYBOOK_LIBRARIES]: {
    name: 'Admin v3',
    url: `${BASE_URL}dashboard`,
  },
};

export default CONFIG;
