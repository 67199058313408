import { isEmpty, sortBy } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import AdminItem from './AdminItem.component';
import AdminForm from './AdminForm.component';
import AdminsMonitoring from './AdminsMonitoring.component';
import LoadIndicator from 'admin/images/load-indicator.gif';

const Admins = (props) => {
  const {
    admins,
    monitoring,
    error,
    isFetching,
    isFetchingMonitoring,
    fetchAdmins,
    fetchMonitoring,
    unsetAdmin,
    setAdmin,
    activeUserId,
  } = props;

  if (isEmpty(admins) && !isFetching && !error) {
    fetchAdmins();
  }

  const renderError = () => (
    <p className="error-line">{error}</p>
  );

  const renderLoading = () => (
    <img src={LoadIndicator} alt="loading" className="loading" />
  );

  const handleUnsetAdmin = (adminId) => {
    if (adminId === activeUserId) {
      const shouldContinue = window.confirm(
        'You\'re about to remove yourself from admins list. '
        + 'As a result, you\' lose access to Admin Panel immediately. '
        + 'Do you still want to continue?'
      );

      if (!shouldContinue) {
        return;
      }
    }

    unsetAdmin(adminId);
  };

  const renderResults = () => {
    const sorted = sortBy(admins, (admin) => admin.id_acl_role);

    return (
      <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
        {sorted.map((admin) => <AdminItem admin={admin} key={admin.id_dms_user} unsetAdmin={handleUnsetAdmin} />)}
      </ul>
    );
  };

  return (
    <div className="main-content">
      <div className="content-container admins-container">
        <h1 className="title">Admins</h1>
        {error && renderError()}

        <section className="admins-section">
          <h2 className="subtitle">
            Current list {isFetching && renderLoading()}
          </h2>
          {renderResults()}
        </section>

        <section className="admins-section">
          <h2 className="subtitle">Set admin role</h2>
          <AdminForm onSubmit={setAdmin} disabled={isFetching} />
        </section>

        <section className="admins-section">
          <h2 className="subtitle">
            Monitoring {isFetchingMonitoring && renderLoading()}
          </h2>
          <AdminsMonitoring
            isFetchingMonitoring={isFetchingMonitoring}
            fetchMonitoring={fetchMonitoring}
            monitoring={monitoring}
          />
        </section>
      </div>
    </div>
  );
};

Admins.propTypes = {
  admins: PropTypes.array.isRequired,
  error: PropTypes.string,
  fetchAdmins: PropTypes.func.isRequired,
  fetchMonitoring: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingMonitoring: PropTypes.bool.isRequired,
  monitoring: PropTypes.array.isRequired,
  setAdmin: PropTypes.func.isRequired,
  unsetAdmin: PropTypes.func.isRequired,
  activeUserId: PropTypes.string,
};

Admins.defaultProps = {
  error: null,
  activeUserId: null,
};

export default Admins;
