import React from 'react';
import PropTypes from 'prop-types';
import { GLOBAL_ELEMENTS } from '@uxpin/shared-components';
import { decode } from '../../helpers/decodePrototypePassword';
import { renderItem, renderLink } from '../Generic/renderItem';

export const PrototypeData = ({ data }) => (
  <ul className={GLOBAL_ELEMENTS.UNORDERED_LIST}>
    {renderItem('Name:', data.name)}
    {renderItem('ID:', data.id_project)}
    {renderItem('Hash:', data.hash)}
    {renderLink('Preview:', data.preview_url)}
    {renderItem('Iterations count:', data.iterations)}
    {renderItem('Last update:', data.last_update)}
    {renderItem('Password:', decode(data.password))}
    {renderLink('Backups:', `/projects/${data.id_project}/project_backups`, {
      anchorText: 'Click here to show available backups',
    })}
    <br />
    {renderItem('Project name:', data.collection_name)}
    {renderItem('Project ID:', data.collection_id)}
    <br />
    {renderItem('Account name:', data.account_name)}
    {renderItem('Account owner email:', data.account_owner_email)}
    {renderLink('Account ID:', `/#/account/${data.account_id}`, {
      anchorText: data.account_id,
    })}
  </ul>
);

PrototypeData.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id_project: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    preview_url: PropTypes.string.isRequired,
    iterations: PropTypes.string.isRequired,
    last_update: PropTypes.string.isRequired,
    password: PropTypes.string,
    collection_name: PropTypes.string.isRequired,
    collection_id: PropTypes.string.isRequired,
    account_name: PropTypes.string,
    account_owner_email: PropTypes.string.isRequired,
    account_id: PropTypes.string.isRequired,
  }).isRequired,
};
