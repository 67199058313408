import { connect } from 'react-redux';
import MenuItemComponent from './MenuItem.component';
import { navigate, searchSetMode } from '../../controllers/actions/search.actions';

export const mapStateToProps = (state) => ({
  activeUrl: state.search.activeUrl,
});

export const mapDispatchToProps = (dispatch) => ({
  searchSetMode: (mode) => {
    dispatch(searchSetMode(mode));
  },
  onNavigation: (url) => {
    dispatch(navigate(url));
  },
});

export const MenuItem = connect(mapStateToProps, mapDispatchToProps)(MenuItemComponent);
