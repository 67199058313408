import { connect } from 'react-redux';
import { searchModes } from '../../constants/search.constants';
import PageComponent from './Page.component';
import { navigateToSearchMode } from '../../controllers/actions/search.actions';

export const mapStateToProps = (state) => {
  const { query, page, isFetching } = state.search;

  return {
    query: query[searchModes.PAGE],
    page,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  navigateToPrototype: (query) => dispatch(navigateToSearchMode(searchModes.PROTOTYPE, query)),
});

export const Page = connect(mapStateToProps, mapDispatchToProps)(PageComponent);
