import React from 'react';
import PropTypes from 'prop-types';
import Cancel from 'admin/images/cancel-01.svg';

const AdminItem = ({ admin, unsetAdmin }) => {
  const onUnsetClick = (e) => {
    e.preventDefault();
    unsetAdmin(admin.id_dms_user);
  };

  return (
    <li className="admin-item">
      <span className="separated">{admin.email}</span>
      <span className="separated">{admin.role}</span>
      <span className="separated">
        <a href="#" title="unset admin" onClick={onUnsetClick}>
          <img alt="unset" src={Cancel} className="remove-icon" />
        </a>
      </span>
    </li>
  );
};

AdminItem.propTypes = {
  admin: PropTypes.object.isRequired,
  unsetAdmin: PropTypes.func.isRequired,
};

export default AdminItem;
