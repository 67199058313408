import { loginActions } from '../../constants/login.constants';
import requestHelper from '../../helpers/requestHelper';
import { logError, errorMessage } from '../../helpers/errorHandler';
import { errors } from '../../constants/errors.constants';
import config from 'react-global-configuration';

const SUPPORT_TOOLS_URL = config.get('SUPPORT_TOOLS_BACKEND_URL');

const loginSetActiveModules = (activeModules) => ({
  type: loginActions.LOGIN_SET_ACTIVE_MODULES,
  activeModules,
});

const loginSetFetchedAuthorization = (isAuthorized) => ({
  type: loginActions.LOGIN_SET_IS_AUTHORIZED,
  isAuthorized,
  isFetching: false,
  hasFetchedAuthorization: true,
});

const loginSetFetching = (isFetching) => ({
  type: loginActions.LOGIN_SET_IS_FETCHING,
  isFetching,
  hasFetchedAuthorization: false,
});

const loginSetActiveUserId = (activeUserId) => ({
  type: loginActions.LOGIN_SET_ACTIVE_USER_ID,
  activeUserId,
});

export const loginFetch = () => (dispatch) => {
  dispatch(loginSetFetching(true));

  requestHelper.get(`${SUPPORT_TOOLS_URL}/auth/`)
    .then((response) => {
      const isAuthorized = response.auth === true;
      dispatch(loginSetFetchedAuthorization(isAuthorized));
      if (isAuthorized) {
        dispatch(loginSetActiveModules(response.modules));
        dispatch(loginSetActiveUserId(response.active_user_id));
      }
    })
    .catch(() => {
      dispatch(loginSetFetchedAuthorization(false));
    });
};

export const handleLoginError = (dispatch, error) => {
  if (errorMessage(error) === errors.PERMISSIONS) {
    dispatch(loginFetch());
  } else {
    logError(error);
  }
};
