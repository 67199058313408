/* eslint-disable react/prop-types */
import { Badge, Tooltip } from '@uxpin/design-system';
import { isEmpty, map } from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import config from 'react-global-configuration';
import Loader from '../Generic/Loader.component';
import { ACCOUNT_STATUS } from '../../constants/statuses.constants';

const tableSettings = {
  minRows: 1,
  className: 'custom-table',
  pageSize: 20,
  showPagination: false,
  showPageSizeOptions: false,
};

const columnSettings = {
  minWidthShort: 50,
  minWidthNormal: 100,
  minWidthWide: 150,
};

const ACCESSORS = {
  OWNERS_EMAIL: 'email',
  PLAN: 'plan_name',
  CREATE_DATE: 'create_date',
  EXPIRAION_DATE: 'expiration_date',
  CANCELED_DATE: 'canceled_date',
  STATUS: 'status',
  IS_TRIAL: 'is_trial',
  PROMOTIONAL: 'promotional',
  ROLE: 'role',
  ID_ACCOUNT: 'id_account',
  SEATS_AMOUNT: 'seats',
  IS_OWNER: 'is_owner',
  IS_BLOCKED: 'is_blocked',
  PROMOTIONS: 'promotions',
};

const { BASE_URL } = config.get();

const emailCell = (props) => {
  const { value, original: { id_dms_user: idUser } } = props;

  return (
    <div>
      <a
        href={`${BASE_URL}#/user/${idUser}/`}
        className="blue-link"
        title={`Go to: ${value}`}>
        {value}
      </a>
    </div>
  );
};

const accountCell = (props) => {
  const { value, original: { id_account: idAccount } } = props;

  return (
    <a
      href={`${BASE_URL}#/account/${idAccount}/`}
      className="blue-link"
      title={`Go to: ${value}`}>
      {value}
    </a>
  );
};

const statusCell = (props) => {
  const { value } = props;

  const classNames = classnames({
    active: value === ACCOUNT_STATUS.ACTIVE,
    suspended: value === ACCOUNT_STATUS.SUSPENDED,
  });

  return (<span className={classNames}>{value}</span>);
};

const booleanCell = (props) => {
  const { value } = props;
  const label = parseInt(value, 10) ? 'Yes' : 'No';

  const classNames = classnames({
    active: !!parseInt(value, 10),
  });

  return (<span className={classNames}>{label}</span>);
};

const promotionsCell = (cellProps) => {
  const { value } = cellProps;

  if (!value || !value.length) {
    return (<Badge appearance="neutral">no promotions</Badge>);
  }

  const filteredPromos = value.filter((promo) => promo.name && promo.id);
  const promoStrings = map(filteredPromos, (promotion) => `${promotion.name} - ${promotion.id}`);

  return (
    <span>
      <Badge>
      available promotions
      </Badge>
      <Tooltip>
        <ul className="g-unordered-list">
          {map(promoStrings, (promo) => <li>{promo}</li>)}
        </ul>
      </Tooltip>
    </span>
  );
};

const RESULTS_COLUMNS = {
  [ACCESSORS.OWNERS_EMAIL]: {
    Header: 'User',
    Cell: emailCell,
    accessor: ACCESSORS.OWNERS_EMAIL,
    minWidth: columnSettings.minWidthWide,
  },
  [ACCESSORS.PLAN]: { Header: 'Plan', accessor: ACCESSORS.PLAN, minWidth: columnSettings.minWidthNormal },
  [ACCESSORS.CREATE_DATE]: { Header: 'Create date', accessor: ACCESSORS.CREATE_DATE },
  [ACCESSORS.EXPIRAION_DATE]: { Header: 'Expiration date', accessor: ACCESSORS.EXPIRAION_DATE },
  [ACCESSORS.CANCELED_DATE]: { Header: 'Canceled at', accessor: ACCESSORS.CANCELED_DATE },
  [ACCESSORS.STATUS]: { Header: 'Status', Cell: statusCell, accessor: ACCESSORS.STATUS },
  [ACCESSORS.IS_TRIAL]: {
    Header: 'Trial', Cell: booleanCell, accessor: ACCESSORS.IS_TRIAL, minWidth: columnSettings.minWidthShort,
  },
  [ACCESSORS.PROMOTIONAL]: { Header: 'Promotional', Cell: booleanCell, accessor: ACCESSORS.PROMOTIONAL },
  [ACCESSORS.ROLE]: { Header: 'Role', accessor: ACCESSORS.ROLE },
  [ACCESSORS.ID_ACCOUNT]: {
    Header: 'Account',
    Cell: accountCell,
    accessor: ACCESSORS.ID_ACCOUNT,
    minWidth: columnSettings.minWidthShort,
  },
  [ACCESSORS.SEATS_AMOUNT]: {
    Header: 'Seats',
    accessor: ACCESSORS.SEATS_AMOUNT,
    minWidth: columnSettings.minWidthShort,
  },
  [ACCESSORS.IS_OWNER]: {
    Header: 'Owner', Cell: booleanCell, accessor: ACCESSORS.IS_OWNER, minWidth: columnSettings.minWidthShort,
  },
  [ACCESSORS.IS_BLOCKED]: {
    Header: 'Blocked', Cell: booleanCell, accessor: ACCESSORS.IS_BLOCKED, minWidth: columnSettings.minWidthShort,
  },
  [ACCESSORS.PROMOTIONS]: {
    Header: 'Promotions', Cell: promotionsCell, accessor: ACCESSORS.PROMOTIONS, minWidth: columnSettings.minWidthWide,
  },
};

const getColumns = () => ([
  RESULTS_COLUMNS[ACCESSORS.ID_ACCOUNT],
  RESULTS_COLUMNS[ACCESSORS.OWNERS_EMAIL],
  RESULTS_COLUMNS[ACCESSORS.IS_OWNER],
  RESULTS_COLUMNS[ACCESSORS.STATUS],
  RESULTS_COLUMNS[ACCESSORS.IS_TRIAL],
  RESULTS_COLUMNS[ACCESSORS.EXPIRAION_DATE],
  RESULTS_COLUMNS[ACCESSORS.PLAN],
  RESULTS_COLUMNS[ACCESSORS.SEATS_AMOUNT],
  RESULTS_COLUMNS[ACCESSORS.PROMOTIONS],
]);

const SearchResults = ({ isFetching, results, query }) => {
  const renderTitle = () => {
    let label;

    if (!isEmpty(results)) {
      label = 'Results';
    } else if (!isFetching && !isEmpty(query)) {
      label = 'Not found';
    }

    return label
      ? <h1 className="title">{label}</h1>
      : null;
  };

  const renderResults = () => {
    const {
      className, minRows, pageSize, showPagination, showPageSizeOptions,
    } = tableSettings;

    if (isFetching && isEmpty(results)) {
      return <Loader />;
    }

    if (isEmpty(results) || isEmpty(query)) {
      return null;
    }

    return (
      <ReactTable
        data={results}
        className={className}
        columns={getColumns()}
        minRows={minRows}
        pageSize={showPagination ? pageSize : results.length}
        showPagination={showPagination}
        showPageSizeOptions={showPageSizeOptions}
      />
    );
  };

  return (
    <div className="main-content">
      <div className="content-container">
        {renderTitle()}
        {renderResults()}
      </div>
    </div>
  );
};

SearchResults.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  results: PropTypes.array,
};

SearchResults.defaultProps = {
  results: [],
};

export default SearchResults;
