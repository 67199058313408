import { Base64 } from 'js-base64';
import { isEmpty } from 'lodash';

const rot13 = (text) => {
  const a = 'a'.charCodeAt(0);
  const n = 'n'.charCodeAt(0);
  const z = 'z'.charCodeAt(0);
  const A = 'A'.charCodeAt(0);
  const N = 'N'.charCodeAt(0);
  const Z = 'Z'.charCodeAt(0);

  let result = '';

  for (let i = 0; i < text.length; i += 1) {
    const char = text[i].charCodeAt(0);
    if ((a <= char && char < n) || (A <= char && char < N)) {
      result += String.fromCharCode(char + 13);
    } else if ((n <= char && char <= z) || (N <= char && char <= Z)) {
      result += String.fromCharCode(char - 13);
    } else {
      result += text[i];
    }
  }

  return result;
};

export const decode = (text) => {
  if (isEmpty(text)) {
    return;
  }

  return rot13(Base64.decode(rot13(text)));
};
