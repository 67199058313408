import React from 'react';
import PropTypes from 'prop-types';

export const DataListItemTitle = ({ title }) => (
  <span className="item-title">{title}</span>
);

DataListItemTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
