/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import config from 'react-global-configuration';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { isEmpty, some } from 'lodash';
import { designSystemStatus } from '../../constants/designSystems.constants';
import {
  getDesignSystemHashFromURL, isValidEmail, isValidHash, isValidDSName, isValidForm,
} from './helpers/form';
import LoadIndicator from 'admin/images/load-indicator.gif';

const DESIGN_SYSTEM_URL = 'designSystemURL';
const USER_EMAIL = 'userEmail';
const DESIGN_SYSTEM_NAME = 'designSystemName';

const getDesignSystemLink = (hash) => `${config.get('APP_URL')}/design-system/${hash}`;

export default class DesignSystems extends React.Component {
  constructor(props) {
    super(props);

    this.inputFieldClassName = 'ds-input__field';
    this.inputClassName = 'ds-input ds-input--width-m';

    this.duplicationInProgressTimeout = null;

    this.state = {
      formField: {
        [DESIGN_SYSTEM_URL]: '',
        [USER_EMAIL]: '',
        [DESIGN_SYSTEM_NAME]: '',
      },
      fieldValid: {
        [DESIGN_SYSTEM_URL]: true,
        [USER_EMAIL]: true,
        [DESIGN_SYSTEM_NAME]: true,
      },
      isDuplicating: false,
      duplicationError: false,
      duplicatedSystemId: null,
      duplicatedSystemHash: null,
      duplicationFinished: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { duplicationResponse, statusResponse } = nextProps;
    const { idDesignSystem: duplicatedSystemId } = duplicationResponse;
    const { status, error } = statusResponse;

    const statusReceived = !isEmpty(status);
    const isDuplicating = (statusReceived && status === designSystemStatus.DUPLICATING);
    const duplicationError = (!isEmpty(error) && !isDuplicating);
    const duplicationFinished = (duplicatedSystemId !== null)
      && statusReceived && (status === designSystemStatus.NORMAL);

    if (isDuplicating) {
      this.checkDuplicationStatus(duplicatedSystemId);
    }

    if (duplicationFinished) {
      clearTimeout(this.duplicationInProgressTimeout);
    }

    const newData = {
      duplicatedSystemId,
      isDuplicating,
      duplicationError,
      duplicationFinished,
    };

    const shoudlUpdtateState = some(newData, (value, key) => (
      typeof value !== 'undefined' && this.state[key] !== value
    ));

    if (shoudlUpdtateState) {
      this.setState(newData);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.duplicationInProgressTimeout);
  }


  getFieldClassName(fieldName) {
    return classnames(this.inputFieldClassName, {
      'is-error': !this.state.fieldValid[fieldName],
    });
  }

  checkDuplicationStatus(duplicatedSystemId) {
    clearTimeout(this.duplicationInProgressTimeout);

    this.duplicationInProgressTimeout = setTimeout(() => {
      this.props.checkDesignSystemStatus(duplicatedSystemId);
      this.checkDuplicationStatus(duplicatedSystemId);
    }, 1500);
  }

  handleValidation(data) {
    const { hash, email, newLibraryName } = data;

    const fieldValid = {
      [DESIGN_SYSTEM_URL]: isValidHash(hash),
      [USER_EMAIL]: isValidEmail(email),
      [DESIGN_SYSTEM_NAME]: isValidDSName(newLibraryName),
    };

    this.setState({ fieldValid });

    return isValidForm(fieldValid);
  }

  @autobind
  handleFieldChange(event) {
    const { name, value } = event.target;
    const { formField } = this.state;
    formField[name] = value;

    this.setState({ formField });
  }

  @autobind
  handleDuplicationResponse(data) {
    if (isEmpty(data) || typeof data === 'undefined') {
      this.setState({
        duplicationError: true,
      });

      return;
    }

    const { idDesignSystem, libraryHash: duplicatedSystemHash } = data.duplicationResponse;

    this.setState({
      duplicatedSystemHash,
      isDuplicating: true,
    });

    if (idDesignSystem) { // initial status check
      this.props.checkDesignSystemStatus(idDesignSystem);
    }
  }

  @autobind
  handleSubmit(e) {
    e.preventDefault();

    const { duplicateDesignSystem } = this.props;
    const { formField } = this.state;

    const formData = {
      hash: getDesignSystemHashFromURL(formField[DESIGN_SYSTEM_URL]),
      email: formField[USER_EMAIL],
      newLibraryName: formField[DESIGN_SYSTEM_NAME],
    };

    const isFormValid = this.handleValidation(formData);

    if (isFormValid) {
      duplicateDesignSystem(formData)
        .then(this.handleDuplicationResponse);
    }
  }

  renderStatusMessage() {
    const {
      isDuplicating,
      duplicationError,
      duplicationFinished,
      duplicatedSystemHash,
    } = this.state;

    if (isDuplicating) {
      return (
        <span className="result-msg">
          <img
            className="inline-loader"
            src={LoadIndicator}
            alt="Working..."
          />
          {' '}
          Duplication in progress, please wait...
        </span>
      );
    } if (duplicationError) {
      return (
        <span className="result-msg is-error">
          An error occurred, the design system was not duplicated. Try again or contact your local developer.
        </span>
      );
    } if (duplicationFinished) {
      const duplicatedSystemLink = getDesignSystemLink(duplicatedSystemHash);

      return (
        <span className="result-msg">
          Duplication finished, here’s the new link:
          {' '}
          <a
            href={duplicatedSystemLink}
            className="ds-link"
            target="_blank"
            rel="noopener noreferrer">
            {duplicatedSystemLink}
          </a>
        </span>
      );
    }

    return null;
  }

  renderDuplicateForm() {
    const { fieldValid } = this.state;
    const { isStartingDuplication } = this.props;

    const inputClass = {
      designSystemURL: this.getFieldClassName(DESIGN_SYSTEM_URL),
      userEmail: this.getFieldClassName(USER_EMAIL),
      designSystemName: this.getFieldClassName(DESIGN_SYSTEM_NAME),
    };

    return (
      <form onSubmit={this.handleSubmit} className="form">
        <fieldset className="field-container regular-width">
          <label htmlFor={DESIGN_SYSTEM_URL}>Source design system link:</label>
          <div className={this.inputClassName}>
            <input
              type="text"
              id={DESIGN_SYSTEM_URL}
              name={DESIGN_SYSTEM_URL}
              className={inputClass[DESIGN_SYSTEM_URL]}
              value={this.state.formField[DESIGN_SYSTEM_URL]}
              onChange={this.handleFieldChange}
            />
            {!fieldValid[DESIGN_SYSTEM_URL]
              && <small className="field-error-msg">Cannot read the library hash.</small>
            }
          </div>
          <small>
            Link format:
            <code>https://app.uxpin.com/design-system/1234567890abcdef</code>
          </small>
        </fieldset>

        <fieldset className="field-container regular-width">
          <label htmlFor={USER_EMAIL}>Target user email:</label>
          <div className={this.inputClassName}>
            <input
              type="text"
              id={USER_EMAIL}
              name={USER_EMAIL}
              className={inputClass[USER_EMAIL]}
              value={this.state.formField[USER_EMAIL]}
              onChange={this.handleFieldChange}
            />
            {!fieldValid[USER_EMAIL]
              && <small className="field-error-msg">Not a valid email address.</small>
            }
          </div>
        </fieldset>

        <fieldset className="field-container regular-width">
          <label htmlFor={DESIGN_SYSTEM_NAME}>Target design system name (optional):</label>
          <div className={this.inputClassName}>
            <input
              type="text"
              id={DESIGN_SYSTEM_NAME}
              name={DESIGN_SYSTEM_NAME}
              className={inputClass[DESIGN_SYSTEM_NAME]}
              value={this.state.formField[DESIGN_SYSTEM_NAME]}
              onChange={this.handleFieldChange}
            />
            {!fieldValid[DESIGN_SYSTEM_NAME]
              && <small className="field-error-msg">Not a valid name.</small>
            }
          </div>
          <small>(leave blank to copy with the same name as original)</small>
        </fieldset>

        {this.renderStatusMessage()}

        <button
          type="submit"
          className={classnames('ds-btn', {
            'ds-btn--is-working': isStartingDuplication,
          })}>
          Duplicate
        </button>
      </form>
    );
  }

  renderDuplicate() {
    return (
      <section>
        <h2 className="subtitle">Duplicate design system</h2>
        {this.renderDuplicateForm()}
      </section>
    );
  }

  render() {
    return (
      <div className="main-content">
        <div className="content-container">
          <h1 className="title">Design systems</h1>
          {this.renderDuplicate()}
        </div>
      </div>
    );
  }
}

DesignSystems.propTypes = {
  duplicateDesignSystem: PropTypes.func.isRequired,
  checkDesignSystemStatus: PropTypes.func.isRequired,
  isStartingDuplication: PropTypes.bool,
  duplicationResponse: PropTypes.object,
  statusResponse: PropTypes.object,
};

DesignSystems.defaultProps = {
  isStartingDuplication: false,
  duplicationResponse: {},
  statusResponse: {},
};
