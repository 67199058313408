import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { rangeFilter, sortFilter } from '../../constants/pageHistory.constants';

export default class PageHistoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({}, props.listFilter);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const field = event.target.name.replace(/([-_][a-z])/ig, (match) => match.toUpperCase().replace('-', ''));
    const value = event.target.value;
    this.setState({ [field]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(Object.assign({}, this.state));
  }

  render() {
    const { isLoading } = this.props;
    const { range, rangeStartDate, rangeStartTime, rangeEndDate, rangeEndTime, sort } = this.state;
    const { RANGE_24H, RANGE_ALL, RANGE_RANGE } = rangeFilter;
    const { SORT_ASC, SORT_DESC } = sortFilter;

    const actionName = isLoading ? 'Loading ...' : 'Load';

    return (
      <p className="history-log-filter">
        <form action="#" onSubmit={this.handleSubmit}>
          <span className="section-wrapper">
            <input
              type="submit"
              value={actionName}
              disabled={isLoading}
              className="history-log-submit-input"
              style={{ width: '85px' }}
            />
          </span>

          <span className="section-wrapper">
            <strong>Sorting:</strong>
          </span>
          <span className="section-wrapper">
            <input
              type="radio"
              id="sort-asc"
              name="sort"
              value={SORT_ASC}
              checked={sort === SORT_ASC}
              onChange={this.handleChange}
            />
            <label htmlFor="sort-asc" className="spaced-label">ASC</label>
          </span>
          <span className="section-wrapper">
            <input
              type="radio"
              id="sort-desc"
              name="sort"
              value={SORT_DESC}
              checked={sort === SORT_DESC}
              onChange={this.handleChange}
            />
            <label htmlFor="sort-desc" className="spaced-label">DESC</label>
          </span>

          <span className="section-wrapper">
            <strong>Range:</strong>
          </span>
          <span className="section-wrapper">
            <input
              type="radio"
              id="range-last-24h"
              name="range"
              value={RANGE_24H}
              checked={range === RANGE_24H}
              onChange={this.handleChange}
            />
            <label htmlFor="range-last-24h" className="spaced-label">last 24h</label>
          </span>
          <span className="section-wrapper">
            <input
              type="radio"
              id="range-all"
              name="range"
              value={RANGE_ALL}
              checked={range === RANGE_ALL}
              onChange={this.handleChange}
            />
            <label htmlFor="range-all" className="spaced-label">all</label>
          </span>
          <span className="section-wrapper">
            <input
              type="radio"
              id="range-range"
              name="range"
              value={RANGE_RANGE}
              checked={range === RANGE_RANGE}
              onChange={this.handleChange}
            />
            <label htmlFor="range-range" className="spaced-label">
              custom:&nbsp;
              <input
                type="date"
                name="range-start-date"
                value={rangeStartDate}
                onChange={this.handleChange}
                className="history-log-inline-input"
                style={{ width: '135px' }}
              />
              <input
                type="time"
                name="range-start-time"
                value={rangeStartTime}
                onChange={this.handleChange}
                className="history-log-inline-input"
                style={{ width: 'auto' }}
              />
              &nbsp;-&nbsp;
              <input
                type="date"
                name="range-end-date"
                value={rangeEndDate}
                onChange={this.handleChange}
                className="history-log-inline-input"
                style={{ width: '135px' }}
              />
              <input
                type="time"
                name="range-end-time"
                value={rangeEndTime}
                onChange={this.handleChange}
                className="history-log-inline-input"
                style={{ width: 'auto' }}
              />
            </label>
          </span>
        </form>
      </p>
    );
  }
}

PageHistoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  listFilter: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
