import { userActions } from '../../constants/user.constants';

export default function userReducer(state = {
  error: null,
  data: {},
  isFetching: false,
  isUpdating: false,
  isResettingPassword: false,
  isRemoving: false,
}, action) {
  switch (action.type) {
    case userActions.USER_SET_ERROR:
      return Object.assign({}, state, {
        error: action.error,
      });
    case userActions.USER_SET_DATA:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, action.data),
        error: action.error,
      });
    case userActions.USER_SET_RESET_PASSWORD_RESULT:
      return Object.assign({}, state, {
        resetPasswordResult: action.resetPasswordResult,
        isResettingPassword: action.isResettingPassword,
        error: action.error,
      });
    case userActions.USER_SET_IS_FETCHING:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      });
    case userActions.USER_SET_IS_UPDATING:
      return Object.assign({}, state, {
        isUpdating: action.isUpdating,
      });
    case userActions.USER_SET_IS_RESETTING_PASSWORD:
      return Object.assign({}, state, {
        isResettingPassword: action.isResettingPassword,
      });
    case userActions.USER_SET_IS_REMOVING:
      return Object.assign({}, state, {
        isRemoving: action.isRemoving,
      });
    default:
      return state;
  }
}
