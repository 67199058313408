import React from 'react';
import PropTypes from 'prop-types';

const PageContentJson = ({ isLoading, value }) => {
  const stringified = isLoading ? 'Loading ...' : JSON.stringify(value, null, 2);

  return (
    <textarea className="json-content" value={stringified} readOnly />
  );
};

PageContentJson.propTypes = {
  isLoading: PropTypes.bool,
  value: PropTypes.object,
};

PageContentJson.defaultProps = {
  isLoading: false,
  value: null,
};

export default PageContentJson;
