import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserComponent from './User.component';
import { userFetch, userUpdate, userResetPassword, userRemove } from '../../controllers/actions/user.actions';
import { navigateToSearchMode } from '../../controllers/actions/search.actions';

const mapStateToProps = (state) => {
  const { data, error, isFetching, isResettingPassword, isUpdating, isRemoving, resetPasswordResult } = state.user;

  return {
    data,
    error,
    isFetching,
    isUpdating,
    isResettingPassword,
    isRemoving,
    resetPasswordResult,
  };
};

const mapDispatchToProps = (dispatch) => (bindActionCreators({
  userFetch,
  userResetPassword,
  userUpdate,
  userRemove,
  navigateToSearchMode,
}, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);
